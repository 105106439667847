.rootPrivacity {
  background-color: theme-color("bgColor");
  //   padding: 50px;
}
.privacityContainer {
  padding-top: 150px;
  color: theme-color("textColor");
}
.privacityContent h2,
.privacityContent h4 {
  color: #fff;
}

.privacityContent h4 {
  margin-top: 60px;
}

@media (max-width: 700px) {
  .rootPrivacity {
    padding-right: 25px;
    padding-left: 25px;
  }
}
