.interviewPage {
  .RootVideo {
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    padding-top: 5em;
    @media (max-width: 991px) {
      & {
        padding-top: 3vw;
      }
    }
    .rowSection {
      width: 80%;
      max-width: 1440px;
    }
  }
}
