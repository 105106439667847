.interviewPage {
  .RootHome {
    width: 100%;
    height: auto;

    padding-top: 3em;
    @media (max-width: 991px) {
      padding-top: 1em;
    }
    display: flex;
    justify-content: center;
    align-items: center;

    // background-color: #080708;
    .rowSection {
      // z-index: 3;
    }
  }

  .ball {
    width: 50px;
    height: 50px;
    position: fixed;
    top: 0;
    left: 0;
    border: 1px solid #fff;
    border-radius: 50%;
    z-index: 999999999999;
    pointer-events: none;
    user-select: none;

    mix-blend-mode: difference;
  }
}
