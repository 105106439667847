.vslPage {
  .rowSection {
    .insideOfCol {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 5%;

      ul {
        margin-top: 3em;
        padding: 0;
        list-style: none;

        width: 100%;
        li {
          display: flex;
          align-items: center;
          &::before {
            content: '';
            font-weight: bold;
            display: inline-block;
            width: 7vw;
            height: 7vw;
            max-width: 30px;
            max-height: 30px;
            margin-right: 1em;
            background-image: url('../../../imgs/icons/asterisk.svg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
          }
        }
      }
    }
    .insideOfRowVideo {
      width: 100%;
      margin-top: 5vw;
      margin-bottom: 3vw;

      .insideOfColVideo {
        width: 100%;
        height: 35vw;
        background: #101010;

        display: flex;
        justify-content: center;
        align-items: center;

        .homePlayContainer {
          width: 100%;
          height: 100%;

          display: flex;
          justify-content: center;
          align-items: center;
          pointer-events: auto;
          &:hover {
            .homePlaySquare {
              transform: rotate(90deg);

              .homePlayIcon {
                transform: rotate(-90deg);
              }
            }
          }
        }

        @media (max-width: 991px) {
          height: 50vw;
          .homePlayContainer {
            margin: 0;
          }
        }
      }
    }
    .insideOfRow {
      width: 70%;
      .button-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        .magnetic-wrap {
          width: fit-content;
        }
      }
    }
    .insideOfColTitle {
      margin-bottom: 2em;
    }
    @media (max-width: 991px) {
      & {
        width: 100%;
      }
      .insideOfColTitle {
        margin-bottom: 0;
      }
      &.insideOfRowSectionTitle {
        margin-bottom: 3em;
        .insideOfColTitle {
          margin-bottom: 2em;
        }
      }
    }
  }
}
