.reservationPage {
  .RootReservation {
    width: 100%;

    padding-top: 5vw;
    padding-bottom: 5vw;

    position: relative;

    justify-content: center;
  }
}
