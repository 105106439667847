.navContent {
  margin-right: 10%;
  transition: 0.3s;
}

#navbar {
  transform: translateY(0);
  display: flex;
  justify-content: space-between;
  .navButtonWrap {
    margin-right: -2em;
  }
  &.hideNavbar {
    transform: translateY(-100%);
  }
  * {
    mix-blend-mode: difference;
  }

  .navbarLogo {
    @media (max-width: 991px) {
      width: 25vw;
      max-height: 50px;
    }
  }
}

// on focus
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: inherit;
}
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: inherit;
}
//
.navbar-brand {
  padding-right: 3em;
}
.navbarBrand {
  color: #fff !important;
}

.navbar-light .navbar-nav .nav-link {
  color: theme-color('textColor') !important;
}

.fslightbox-open .fixed-top {
  z-index: 1;
}
.fixed-top {
  top: 0;
  height: 127px;
}

#navbar {
  transform: translate3d(0, 0, 0);
  transition: transform 0.5s ease-in-out;

  //   BUTTON STYLE
  .buttonStyle {
    font-family: 'Whyte Inktrap';
    font-style: normal;
    font-weight: bold;
    font-size: 1vw;
    line-height: 2vw;
    letter-spacing: 0.085em;

    background-color: transparent;
    color: theme-color('textColor');
    border: 1px solid theme-color('textColor');
    border-radius: 25px;

    padding: 0.8em 1em;

    @media (max-width: 991px) and (min-width: 600px) {
      font-size: 15px;
      line-height: 20px;
    }
    @media (max-width: 599px) {
      font-size: 3vw;
      line-height: 3.5vw;
      border-radius: 3vw;
    }
  }
}

.navCollapse {
  display: flex;
  justify-content: space-between;
}

.navbar-light .navbar-nav .nav-link {
  color: theme-color('textColor');
  margin-right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  transition: 0.3s;
  // font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;

  text-align: center;
  letter-spacing: 0.195em;
  text-transform: uppercase;
}

.navbar-light .navbar-nav .nav-link {
  color: #fff;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: theme-color('primary') !important;
}

.nav-color-top {
  background-color: transparent;
  transition: 1s;
}

.nav-color-scroll {
  background-color: transparent !important;
  mix-blend-mode: difference;

  padding-right: 5vw;
  padding-left: 5vw;
}

#navbar {
  // transition: top 0.3s, 0.5s;
}

.navbar--hidden {
  top: -50px;
}

.navbar-collapse {
  // position: absolute;
  right: 86px;
}

//TOGGLER
.navbar-toggler {
  border: none;
  font-size: 2em;
}

.navVariantIfScroll {
  .navButtonWrap {
    display: none;
    opacity: 0;
    visibility: hidden;
  }
}
.RichAndBruno {
  a {
    color: theme-color('textColor');
  }
}

.navCenter {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

/* RESPONSIVE STYLE */

@media (max-width: 991px) {
  #navbar {
    height: 72px;
    width: 100vw;
    overflow: hidden;
    // padding-left: 0;
    // padding-right: 0;
    padding-top: 15px;

    .magnetic-wrap {
      margin: 0 !important;
      .magnetic-size {
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
      }
    }
    .navButtonWrap {
      margin-right: 0;
    }
  }

  #containerNavToggle {
    height: 100% !important;
  }

  .nav-color {
    background: rgba(0, 0, 0, 0.747);
  }

  // .fixed-top {
  //    height: 70px;
  // }

  .navbar-light .navbar-nav .nav-link {
    text-align: center;
  }

  .nav-link {
    padding: 3vw;
  }

  .nav-link:hover {
    background-color: rgba(0, 0, 0, 0.541);
    color: theme-color('primary');
  }

  .navbar-light .navbar-nav .nav-link {
    margin-right: 0;
  }

  .navbar-collapse {
    position: initial;
    right: 0;
  }

  .custom-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0, 0.8)' stroke-width='4' stroke-linecap='butt' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  }

  .custom-toggler.navbar-toggler {
    border-color: rgb(255, 102, 203);
  }

  .navbar-toggler {
    margin-right: 20px;
  }

  .navbar-collapse {
    background-color: #fff;
  }
}
