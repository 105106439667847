.vslPage {
  .RootInfo {
    padding-top: 7.5vw;
    padding-bottom: 7.5vw;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}
