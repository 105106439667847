.reservationPage {
  .RootSituations {
    width: 100%;
    padding-top: 1vw;
    padding-bottom: 5vw;
    margin-top: 3vw;

    justify-content: center;
  }
}
