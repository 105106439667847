.vslPage {
  .rowSection {
    .nftGiftRow {
      // max-width: 70%;
      width: 100%;
      .nftGiftCol {
        display: flex;
        justify-content: center;
        align-items: center;
        .cardStyle {
          .cardsFont {
            font-family: 'Whyte Inktrap';
            font-weight: 400;
            font-size: 1.3vw;
            line-height: 1.8vw;
            margin-bottom: 2em;
            @media (max-width: 991px) {
              font-size: 4.3vw;
              line-height: 4.8vw;
            }
          }
          ul li {
            font-family: 'Roboto';
            font-style: italic;
            font-weight: normal;
            font-size: 1.1vw;
            line-height: 1.4vw;

            @media (max-width: 991px) {
              font-size: 3.5vw;
              line-height: 4vw;
            }
          }
        }
        .magnetic-wrap {
          width: fit-content;
        }
        .nftGiftImg {
          width: 100%;
          border-radius: 20px;
          @media (max-width: 991px) {
            width: 100%;
            border-radius: 3vw;

            margin-bottom: 8vw;
          }
        }
        .button-wrapper {
          display: flex;
          justify-content: center;
          .magnetic-wrap {
            margin: 0;
          }
        }
        .reservationButton {
          width: 100%;
          height: auto;
          // padding-bottom: 30%;
          // padding-top: 30%;

          padding: 3vw;
          border-radius: 50%;
          flex: 1 0 auto;
          &::after {
            content: '';
            display: block;
            padding-bottom: 100%;
          }
        }
        &.nftGiftColBtn {
          @media (max-width: 991px) {
            margin: 10vw 0 0 0;
          }
        }
      }
      @media (max-width: 991px) {
        & {
          max-width: 100%;
        }
      }
    }
  }
}
