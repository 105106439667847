/*******************************
*
* Customize your CSS here
*
********************************/

body {
  background-color: theme-color('bgColor');
}
[data-scrollbar] {
  // overflow: hidden !important;
}

* {
  cursor: none;
}

.row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.customList {
  list-style: none;
  padding-left: 0;

  // width: 60%;
  li {
    display: flex;
    text-align: left;
    // align-items: center;
    &::before {
      content: '';
      font-weight: bold;
      display: inline-block;
      width: 7vw;
      height: 7vw;
      max-width: 15px;
      max-height: 15px;
      margin-right: 1em;
      background-image: url('../../imgs/icons/asterisk.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
    h5 {
      font-style: italic;
    }
  }
}

.gs_reveal {
  opacity: 0;
  visibility: hidden;
  will-change: transform, opacity;
}

.gs_reveal_title {
  // overflow: hidden;
  // opacity: 0;
  // visibility: hidden;
  margin-bottom: 0;
}

.comillasContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  //   padding-left: 40px;
  .comillasStyle {
    position: absolute;
    height: 100%;
    width: auto;
    max-width: 30px;
    left: 0;
  }
}

.sectionJumping {
  overflow-x: hidden;
  // @media (max-width: 991px) {
  // }
}
.vslPage {
  .sectionJumping {
    overflow-x: unset;
    @media (max-width: 991px) {
      overflow-x: hidden;
    }
  }
}
