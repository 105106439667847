.reservationPage {
  .RootHome {
    width: 100%;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;

    // background-color: #080708;
    .rowSection {
      z-index: 3;
    }
    @media (max-height: 750px) and (min-width: 992px) {
      & {
        padding-top: 80px;
      }
    }
    @media (max-height: 680px) and (min-width: 992px) {
      & {
        padding-top: 160px;
      }
    }
    @media (max-height: 580px) and (min-width: 992px) {
      & {
        padding-top: 210px;
      }
    }
    @media (max-width: 991px) {
      & {
        height: auto;
      }
    }
  }

  .ball {
    width: 50px;
    height: 50px;
    position: fixed;
    top: 0;
    left: 0;
    border: 1px solid #fff;
    border-radius: 50%;
    z-index: 999999999999;
    pointer-events: all;
    user-select: none;

    mix-blend-mode: difference;
  }
}
