.exitPopUpModal {
  display: flex !important;
  justify-content: center;
  align-items: center;
  .modal-dialog {
    margin: 0;
    max-width: 800px;
  }
  .modal-content {
    background-color: theme-color('bgColor');

    border: 1px solid theme-color('primary');
    padding: 1vw;
    .modal-header {
      border: none;
      padding: 0;
      * {
        cursor: none;
      }

      .close {
        color: theme-color('secondary');
      }
      & > div {
        text-align: left;
        position: relative;
        z-index: 1;
      }
      h1 {
        font-size: 3vw;
        line-height: 3vw;
        margin-bottom: 1vw;
      }
      h2 {
        font-size: 1.5vw;
        line-height: 2vw;
      }
      p {
        font-size: 0.9vw;
        line-height: 1.2vw;
        margin-top: 1vw;
      }
    }
    .exitPopUpCol {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .ebookImg {
      // position: absolute;
      height: 100%;
      width: calc(100% + 25%);
      object-fit: contain;
      object-position: right;
      // z-index: -1;

      // opacity: 0.6;
    }
  }
}

.formStyle {
  ._form_37,
  // ._form_41,
  ._form_39 {
    ._has_error {
      border-bottom: 1px solid #f37c7b !important;
      animation: shake 0.5s;
    }
    ._error-inner,
    ._error-arrow {
      display: none;
    }
  }
  .noSpamText {
    a {
      color: theme-color('primary');
    }
  }

  ._form {
    width: 100% !important;
    padding-left: 0 !important;
    margin-top: 0 !important;
    background-color: transparent !important;

    * {
      font-family: 'Whyte Inktrap';
      font-weight: 400;
    }

    ._field-wrapper input {
      border-top: none;
      border-left: none;
      border-right: none;
      border-radius: 0;
      border-color: theme-color('textColor');
      color: theme-color('textColor') !important;
      // FONT
      font-family: 'Whyte Inktrap';
      font-style: normal;
      font-weight: normal;
      font-size: 19px;
      @media (max-width: 1600px) {
        & {
          font-size: 1.3vw;
          line-height: 1.5vw;
        }
      }
      @media (max-width: 991px) {
        & {
          font-size: 3.5vw !important;
          line-height: 4.5vw;
        }
      }
      line-height: 24px;

      &::placeholder {
        color: theme-color('textColor');
        // opacity: 0.5;
        overflow: visible;
        line-height: normal !important;
      }
      &::-webkit-input-placeholder {
        line-height: normal !important;
        color: theme-color('textColor');
        // opacity: 0.5;
        overflow: visible;
      }
    }
    ._form_element {
      margin-bottom: 2vw;
    }
  }

  ._form-label {
    display: none !important;
  }

  #_form_5F2D0E9113170_._inline-style {
    width: 100%;
    display: inline-block;
  }

  ._x64724438 input {
    color: #fff;
  }

  // ._field9 select {

  // }
  ._form_element ._html-code p {
    font-family: 'Whyte Inktrap';
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    a {
      font-size: 13px;
      line-height: 17px;
    }

    @media (max-width: 991px) {
      &,
      a {
        font-size: 3vw;
        line-height: 3.5vw;
      }
    }
  }
  ._field-wrapper input {
    background-color: transparent;
    padding-left: 10px;
    padding-right: 10px;
    // height: 50px !important;
    font-size: 1.2em;
  }

  // ._inline-style input[type="text"] {
  //   width: 350px !important;
  //   font-size: 20px !important;
  //   background-color: transparent;
  //   background-image: url("../../../../assets/img/icons/olivialucia-input.svg");
  //   background-size: 100%;
  //   background-repeat: no-repeat;
  //   background-position: center;
  //   border: none !important;
  // }

  ._form-fieldset {
    display: flex;
    @media (max-width: 991px) {
      & {
        display: block;
      }
    }
  }

  #_form_37_submit,
  #_form_41_submit,
  #_form_39_submit {
    position: relative;
    // margin: 0 auto;
    // width: 9vw;
    // max-width: 130px;
    // height: 9vw;
    // max-height: 130px;
    // border-radius: 50% !important;
    // display: flex;
    // justify-content: center;
    // align-items: center;

    // background-color: theme-color('primary');
    // border: none;

    // color: theme-color('bgColor');

    // FONT
    font-family: 'Whyte Inktrap';
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    border: 1.5px solid theme-color('primary') !important;

    transition: color 0.3s, background-color 0.3s;

    @media (max-width: 1600px) {
      & {
        font-size: 1.3vw;
        line-height: 1.5vw;
      }
    }

    // &.reservationEffect {
    //   transition: background-color 0.3s, border 0.3s, color 0.3s;

    //   &:hover {
    //     background-color: transparent !important;

    //     border: 1.5px solid theme-color('primary') !important;

    //     color: theme-color('textColor') !important;
    //   }
    // }

    @media (max-width: 991px) {
      & {
        // FONT
        font-size: 3.5vw;
        line-height: 4vw;
      }
      &:hover,
      &:active {
        background-color: transparent !important;

        color: theme-color('textColor') !important;
      }
    }
    &:hover,
    &:active {
      cursor: none;

      background-color: transparent !important;

      border: 1.5px solid theme-color('primary') !important;

      color: theme-color('textColor') !important;
    }

    &:focus {
      outline: none;
    }
  }

  #_form_37_submit::after,
  #_form_41_submit::after,
  #_form_39_submit::after {
    content: '';
    height: 64px;
    width: 100%;

    // background-image: url("../../../../assets/img/icons/olivialucia-boton.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100% !important;

    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }

  ._error-inner._no_arrow {
    display: none;
  }

  ._inline-style:not(._clear) + ._inline-style:not(._clear) {
    margin-left: 0 !important;
  }

  ._button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 !important;

    padding-top: 2em;

    @media (max-width: 991px) {
      & {
        padding-top: 1em;
      }
    }
  }

  ._inline-style:not(._clear) + ._inline-style:not(._clear) {
    margin-left: 20px !important;
  }

  ._inline-style input[type='text'],
  ._inline-style input[type='date'] {
    padding: 10px 28px !important;
  }

  label {
    display: inline-block;
    margin-bottom: 0;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: theme-color('textColor') !important;
    // background-image: url("../../../../assets/img/icons/olivialucia-input.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
  }

  ._form-thank-you {
    text-align: left !important;
  }

  // ._field11 {
  //   position: absolute !important;
  //   bottom: -40px;
  // }
  ._form {
    padding-top: 0 !important;
  }

  input[type='checkbox']._has_error {
    outline: theme-color('primary') 1px solid !important;
    animation: shake 0.5s;
  }

  ._form_element._clear {
    margin-bottom: 0 !important;
  }

  .policyStyle {
    color: #fff;
    text-decoration: underline !important;
  }

  @media (max-width: 991px) {
    ._form_37,
    ._form_41,
    ._form_39 {
      &:nth-child(1) {
        // border: none !important;
        border-width: 2px;
        padding: 7vw 5vw;
        // border-top: none;
        // border-bottom: none;
      }
    }
  }
}

// ANIMATIONS
@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
