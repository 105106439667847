.reservationPage {
  // FORM
  // ._field11 {
  //   position: absolute !important;
  //   bottom: -70px;
  // }
  ._form_31 {
    &:nth-child(1) {
      max-width: 600px;
      width: 100%;
      border: 1px solid theme-color('primary');
      padding: 4em;
      border-radius: 25px;
    }
  }
  .noSpamText {
    a {
      color: theme-color('primary');
    }
  }

  ._form {
    width: 100% !important;
    padding-left: 0 !important;
    margin-top: 0 !important;
    background-color: transparent !important;

    * {
      font-family: 'Whyte Inktrap';
      font-weight: 400;
    }

    ._field-wrapper input {
      border-top: none;
      border-left: none;
      border-right: none;
      border-radius: 0;
      border-color: theme-color('textColor');
      color: theme-color('textColor') !important;
      // FONT
      font-family: 'Whyte Inktrap';
      font-style: normal;
      font-weight: normal;
      font-size: 19px;
      @media (max-width: 1600px) {
        & {
          font-size: 1.3vw;
          line-height: 1.5vw;
        }
      }
      @media (max-width: 991px) {
        & {
          font-size: 3.5vw !important;
          line-height: 4.5vw;
        }
      }
      line-height: 24px;

      &::placeholder {
        color: theme-color('textColor');
        // opacity: 0.5;
        overflow: visible;
        line-height: normal !important;
      }
      &::-webkit-input-placeholder {
        line-height: normal !important;
        color: theme-color('textColor');
        // opacity: 0.5;
        overflow: visible;
      }
    }
    ._form_element {
      margin-bottom: 2vw;
    }
  }

  ._form-label {
    display: none !important;
  }

  #_form_5F2D0E9113170_._inline-style {
    width: 100%;
    display: inline-block;
  }

  ._x64724438 input {
    color: #fff;
  }

  // ._field9 select {

  // }
  ._form_element ._html-code p {
    font-family: 'Whyte Inktrap';
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    a {
      font-size: 13px;
      line-height: 17px;
    }

    @media (max-width: 991px) {
      &,
      a {
        font-size: 3vw;
        line-height: 3.5vw;
      }
    }
  }
  ._field-wrapper input {
    background-color: transparent;
    padding-left: 10px;
    padding-right: 10px;
    // height: 50px !important;
    font-size: 1.2em;
  }

  // ._inline-style input[type="text"] {
  //   width: 350px !important;
  //   font-size: 20px !important;
  //   background-color: transparent;
  //   background-image: url("../../../../assets/img/icons/olivialucia-input.svg");
  //   background-size: 100%;
  //   background-repeat: no-repeat;
  //   background-position: center;
  //   border: none !important;
  // }

  ._form-fieldset {
    display: flex;
    @media (max-width: 991px) {
      & {
        display: block;
      }
    }
  }
  ._checkbox-radio {
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
    // padding-left: 0 !important;
    // padding-right: 0 !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    /* DEFAULT RADIO CHECKBOX */
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    /* CUSTOM CHECKBOX */
    .checkmark {
      position: absolute;
      left: 0;
      top: 0;
      height: 15px;
      width: 15px;
      background-color: transparent;
      border: 1px solid theme-color('primary');
      flex: none;

      display: flex;
      justify-content: center;
      align-items: center;

      pointer-events: none;
    }
    /* WHEN CHECKBOX CHECKED ADD BACKGROUND PRIMARY */
    input:checked ~ .checkmark {
      background-color: theme-color('primary') !important;
    }
    /* Show the checkmark when checked */
    input:checked ~ .checkmark:after {
      display: block;
    }
    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }

    .checkmark:after {
      width: 5px;
      height: 10px;
      border: solid theme-color('bgColor');
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    label {
      font-family: 'Whyte Inktrap';
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 17px;
      display: block !important;

      padding-left: 25px;

      @media (max-width: 991px) {
        & {
          font-size: 3.5vw;
          line-height: 4vw;
        }
      }
    }
  }
  #_form_31_submit {
    position: relative;
    margin: 0 auto;
    width: 9vw;
    max-width: 130px;
    height: 9vw;
    max-height: 130px;
    border-radius: 50% !important;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: theme-color('primary');
    border: none;

    color: theme-color('bgColor');

    // FONT
    font-family: 'Whyte Inktrap';
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;

    @media (max-width: 1600px) {
      & {
        font-size: 1.3vw;
        line-height: 1.5vw;
      }
    }

    &.reservationEffect {
      transition: background-color 0.3s, border 0.3s, color 0.3s;

      &:hover {
        background-color: transparent !important;

        border: 1.5px solid theme-color('primary') !important;

        color: theme-color('textColor') !important;
      }
    }

    @media (max-width: 991px) {
      & {
        width: 30vw;
        height: 30vw;
        max-width: 30vw;
        max-height: 30vw;

        // FONT
        font-size: 3.5vw;
        line-height: 4vw;
      }
      &:hover,
      &:active {
        background-color: transparent !important;

        border: 1.5px solid theme-color('primary') !important;

        color: theme-color('textColor') !important;
      }
    }

    &:hover {
      cursor: none;
    }
    &:focus {
      outline: none;
    }
  }

  #_form_31_submit::after {
    content: '';
    height: 64px;
    width: 100%;

    // background-image: url("../../../../assets/img/icons/olivialucia-boton.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100% !important;

    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }

  ._error-inner._no_arrow {
    display: none;
  }

  ._inline-style:not(._clear) + ._inline-style:not(._clear) {
    margin-left: 0 !important;
  }

  ._button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 !important;

    padding-top: 2em;

    @media (max-width: 991px) {
      & {
        padding-top: 1em;
      }
    }
  }

  ._inline-style:not(._clear) + ._inline-style:not(._clear) {
    margin-left: 20px !important;
  }

  ._inline-style input[type='text'],
  ._inline-style input[type='date'] {
    padding: 10px 28px !important;
  }

  label {
    display: inline-block;
    margin-bottom: 0;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: theme-color('textColor') !important;
    // background-image: url("../../../../assets/img/icons/olivialucia-input.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
  }

  ._form-thank-you {
    text-align: left !important;
  }

  // ._field11 {
  //   position: absolute !important;
  //   bottom: -40px;
  // }
  ._form {
    padding-top: 0 !important;
  }

  input[type='checkbox']._has_error {
    outline: theme-color('primary') 1px solid !important;
    animation: shake 0.5s;
  }

  ._form_element._clear {
    margin-bottom: 0 !important;
  }

  .policyStyle {
    color: #fff;
    text-decoration: underline !important;
  }

  @media (max-width: 991px) {
    ._form_31 {
      &:nth-child(1) {
        // border: none !important;
        border-width: 2px;
        padding: 7vw 5vw;
        // border-top: none;
        // border-bottom: none;
      }
    }
  }
}

#dropdownFieldForm {
  padding: 0 !important;
  cursor: none;
  * {
    cursor: none;
  }
  button {
    position: relative;
    background-color: transparent !important;
    width: 100%;
    box-shadow: none;
    overflow: hidden;

    &::after {
      border: none;
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      content: '\f0dd';
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 15px;
      display: flex;
      justify-content: center;
      align-items: center;

      padding: 0 0.8em;

      background: theme-color('bgColor');
    }
  }
  .dropdown-menu {
    background-color: #0f0f0f;
    display: block;
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: 1px solid theme-color('textColor');

    .dropdown-item {
      // display: block;
      color: theme-color('textColor') !important;
      font-family: 'Whyte Inktrap';
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      padding-top: 1em;
      padding-bottom: 1em;
      border-bottom: 1px solid #e1e1e148;
      border-top: 1px solid #e1e1e148;
      &:nth-child(1) {
        & {
          border-top: none;
          border-bottom: none;
        }
      }
      &:nth-last-child(1) {
        & {
          border-top: none;
          border-bottom: none;
        }
      }
      @media (max-width: 991px) {
        & {
          font-size: 3.5vw !important;
          line-height: 4.5vw;
        }
      }

      white-space: normal;

      overflow: hidden;

      &:hover {
        color: theme-color('bgColor') !important;
        background-color: theme-color('primary') !important;
      }

      &:focus {
        color: theme-color('bgColor') !important;
        background-color: theme-color('primary') !important;
      }
    }
  }
}
#dropdownFieldForm,
#dropdownFieldForm button {
  @extend input;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  border-color: theme-color('textColor');
  color: theme-color('textColor') !important;
  // FONT
  font-family: 'Whyte Inktrap';
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 24px;

  // height: 50px !important;

  text-align: left;

  @media (max-width: 991px) {
    & {
      font-size: 3.5vw !important;
      line-height: 4.5vw;
    }
  }
}
._field-wrapper select {
  @extend input;
  display: none !important;
  * {
    box-shadow: none !important;
    border: none !important;
    outline: none !important;
    color: theme-color('textColor');
  }
}

._field-wrapper select option {
  margin: 40px;
  background: theme-color('bgColor');
  color: theme-color('textColor');
  border: none;
  border-left: 1px solid theme-color('primary');

  // FONT
  font-family: 'Whyte Inktrap';
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;

  // TODO: PADDING IN OPTIONS

  &:nth-child(1) {
    display: none;
  }
}

.interviewPage {
  ._form_element {
    margin-bottom: 1vw !important;
    @media (max-width: 991px) {
      margin-bottom: 2vw !important;
    }
  }
}
