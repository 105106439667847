@font-face {
  font-family: 'Whyte Inktrap';

  src: url('./WhyteInktrap-Bold.eot'); /* IE9*/
  src: url('./WhyteInktrap-Bold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./WhyteInktrap-Bold.woff2') format('woff2'),
    /* chrome、firefox */ url('./WhyteInktrap-Bold.woff') format('woff'),
    /* chrome、firefox */ url('./WhyteInktrap-Bold.ttf') format('truetype'); /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/

  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Whyte Inktrap';
  src: local('Whyte Inktrap Regular'), local('Whyte-Inktrap-Regular'),
    url('WhyteInktrap-Regular.woff2') format('woff2'),
    url('WhyteInktrap-Regular.woff') format('woff'),
    url('WhyteInktrap-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
