.coursePurchased {
  &.thanksPageRow {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    //   margin: 0 auto;
    .rowSection {
      height: auto;
      justify-content: center;
      max-width: 100%;
      @media (max-width: 991px) {
        max-width: 95%;
      }

      #homeCanvas {
        position: fixed;
        left: 50%;
        // top: 50%;
        transform: translateX(-50%);
        top: 0;
        // left: 0;
        z-index: -1;
        width: auto;
        height: 100vh;

        opacity: 0.3;

        @media (max-width: 991px) {
          & {
            height: 100%;
            width: auto;
            max-width: 100%;
            top: 0;
          }
        }
      }

      .thanksPageCol {
        max-width: 1300px;
        width: 80%;
        padding-left: 0;
        padding-right: 0;
        &.thanksPageColTitle {
          margin-top: 5vw;
          text-align: center;
          @media (max-width: 991px) {
            margin-top: 10vw;
          }
        }

        .thanksYouArrowDown {
          margin: 2vw 0;
          @media (max-width: 991px) {
            width: 15vw;
            margin: 8vw 0 4vw 0;
          }
        }

        @media (max-width: 1440px) {
          max-width: 90%;
          width: 100%;
        }

        h1 {
          font-size: 3vw;
          line-height: 3.5vw;
          text-align: center;
          // margin-bottom: 2vw;
          @media (max-width: 991px) {
            font-size: 6vw;
            line-height: 6.5vw;
          }
        }

        .ctaWrap {
          margin-top: 5vw;
          padding: 3vw 0 2vw 0;
          border-top: 1px solid theme-color('secondary');
          border-bottom: 1px solid theme-color('secondary');
          @media (max-width: 991px) {
            margin-top: 10vw;
            padding: 10vw 0 3vw 0;
          }
        }

        h3 {
          font-size: 30px;
          line-height: 35px;

          text-align: center;

          opacity: 0.6;

          @media (max-width: 1600px) {
            font-size: 2vw;
            line-height: 2.5vw;
          }
          @media (max-width: 991px) {
            font-size: 5vw;
            line-height: 5.5vw;
          }
        }
      }
    }

    .footerText {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 17px;
    }
  }
}
.thanksPageRow {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  //   margin: 0 auto;
  .rowSection {
    height: auto;
    justify-content: center;
    max-width: 100%;
    @media (max-width: 991px) {
      max-width: 100%;
    }

    .thanksPageCol {
      max-width: 1300px;
      width: 80%;
      padding-left: 0;
      padding-right: 0;
      &.thanksPageColArrow {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3vw 0 2vw 0;

        @media (max-width: 991px) {
          svg {
            width: 13vw;
          }
        }
      }
      &.thanksPageColTitle {
        display: flex;
        justify-content: center;
        align-items: center;

        margin-bottom: 3vw;
        text-align: center;
        @media (max-width: 991px) {
          margin-bottom: 10vw;
        }
      }
      @media (max-width: 1440px) {
        max-width: 90%;
        width: 100%;
      }
      .cardDeckThanks {
        margin-top: 3em;
        .thanksCards {
          padding: 1em;
          @media (max-width: 991px) {
            padding: 1vw;
          }
        }
        .thanksCardBody {
          font-size: 1.5vw;
          line-height: 2vw;
          @media (max-width: 991px) {
            font-size: 3.5vw;
            line-height: 4vw;
          }
        }
      }
      h1 {
        font-size: 3vw;
        line-height: 3.5vw;
        @media (max-width: 991px) {
          font-size: 7vw;
          line-height: 7.5vw;
        }
      }
      h2 {
        text-align: center;
      }
      // h3 {
      //   font-size: 20px;
      //   line-height: 25px;
      //   text-align: center;
      //   &:nth-child(1) {
      //     margin-top: 1vw;
      //   }
      //   &:nth-last-child(1) {
      //     margin-top: 1vw;
      //   }
      //   @media (max-width: 1600px) {
      //     font-size: 1.5vw;
      //     line-height: 2vw;
      //   }
      //   @media (max-width: 991px) {
      //     font-size: 3.5vw;
      //     line-height: 4vw;
      //     &:nth-last-child(1) {
      //       margin-top: 5vw;
      //     }
      //   }
      // }
      &:nth-child(1) {
        padding-top: 3em;
        @media (max-width: 991px) {
          padding-top: 10vw;
        }
      }
      &:nth-last-child(1) {
        margin-top: 3em;
        padding-bottom: 5em;
        @media (max-width: 991px) {
          margin-top: 5vw;

          padding-bottom: 5vw;
        }
      }
      .thanksPageInstas {
        text-align: left;
        margin: 0;

        a {
          color: theme-color('secondary');
          transition: color 0.3s;
          &:hover {
            color: theme-color('primary');
          }
        }

        &.thanksPageInstasBruno {
          @media (max-width: 991px) {
            margin-bottom: 3vw !important;
          }
        }
      }

      .button-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        .buttonStyle {
          color: theme-color('primary');

          text-transform: uppercase;

          transition: background-color 0.3s, color 0.3s;
        }
        .magnetic-wrap {
          &:hover {
            .buttonStyle {
              color: theme-color('bgColor');
              background: theme-color('secondary');
            }
          }
        }
      }

      &.thanksPageColLogo {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 30%;

          @media (max-width: 991px) {
            width: 80%;
          }
        }
        @media (max-width: 991px) {
          margin-top: 13vw;
          padding-bottom: 10vw;
        }
      }

      .thanksPageColWrap {
        display: flex;
        justify-content: center;
        align-items: center;

        .thanksPageDivWrap {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          .thanksPageNftImg {
            width: 80%;
            // object-fit: contain;

            opacity: 0.2;
          }
          p {
            position: absolute;
            width: 60%;
            text-align: center;

            font-family: 'Roboto';
            font-style: normal;
            font-weight: normal;
            font-size: 1.8vw;
            line-height: 2.1vw;
            .thanksyouCiteIcon {
              position: absolute;
              top: 0;
              svg {
                width: 2vw;
              }

              &:nth-child(1) {
                right: 100%;
                svg {
                  margin-right: 10px;
                }
              }
              &:nth-child(2) {
                left: 100%;
                svg {
                  margin-left: 10px;
                }
              }
            }
            @media (max-width: 991px) {
              font-size: 4.8vw;
              line-height: 5.1vw;
              width: 80%;

              .thanksyouCiteIcon {
                svg {
                  width: 5vw;
                }
              }
            }
          }
        }
        & > div {
          text-align: center;

          .thanksYouArrowDown {
            margin: 2vw 0;
            @media (max-width: 991px) {
              width: 15vw;
              margin: 8vw 0 4vw 0;
            }
          }
        }
        @media (max-width: 991px) {
          width: 90%;
        }
      }
      .thanksYouListGroup {
        .thanksYouListItem {
          padding: 5vw 3vw;
          margin: 2vw 0;
          &.stepBloqued {
            opacity: 0.2;
            * {
              pointer-events: none;
            }
          }
          &.active {
            .thanksStepCheck {
              opacity: 1 !important;
            }
          }
          &:nth-child(1) {
            margin-top: 0;
          }
          &:nth-last-child(1) {
            margin-bottom: 0;
          }

          &.thanksYouListItemTelegram {
            background-color: theme-color('primary');
            border-radius: 25px;

            .thanksStepCheckBox {
              border-color: theme-color('secondary') !important;
            }
            @media (max-width: 991px) {
              border-radius: 0;
            }
          }

          h2 {
            font-family: 'Cosi Times';
            font-style: normal;
            font-weight: normal;
            font-size: 85px;
            line-height: 90px;

            text-align: left;

            @media (max-width: 991px) {
              font-size: 12vw;
              line-height: 11.5vw;
              margin-bottom: 0;
            }
          }

          .thanksStepWrap {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .thanksStepCheckBox {
              position: relative;
              border-radius: 50%;

              width: 3vw;
              height: 3vw;

              background-color: transparent;

              border: 1px solid theme-color('primary');

              display: flex;
              justify-content: center;
              align-items: center;

              margin-right: 2vw;

              .thanksStepCheck {
                position: absolute;
                width: 3vw;
                height: 3vw;
                left: 0.5vw;
                bottom: 0.2vw;
                opacity: 0;
              }
              @media (max-width: 991px) {
                width: 8vw;
                height: 8vw;
                .thanksStepCheck {
                  width: 8vw;
                  height: 8vw;
                  left: 0.5vw;
                  // bottom: -1vw;
                  opacity: 0;
                }
              }
            }
            @media (max-width: 991px) {
              margin-bottom: 2vw;
            }
          }
          .thanksStepWrapContent {
            margin: 1vw 0 2vw 5vw;
            .instagramEvent {
              text-transform: uppercase;
              font-weight: 700;
            }
            p {
              margin: 0;
              @media (max-width: 991px) {
                font-size: 3.8vw;
                line-height: 4.8vw;
              }
            }
            .boldText {
              font-weight: 900;
            }

            .marginBottomText {
              margin-bottom: 2vw;
              @media (max-width: 991px) {
                margin-bottom: 5vw;
              }
            }
            @media (max-width: 991px) {
              margin: 3vw 0 6vw 10.5vw;
            }
          }
          .thanksYouListItemDiv {
            border-bottom: 1px solid theme-color('secondary');

            .button-wrapper {
              margin-left: -2em;
              justify-content: flex-start;
              .js-magnetic-area:hover ~ .buttonStyle {
                background-color: transparent !important;

                border: 2px solid theme-color('secondary') !important;

                color: theme-color('textColor') !important;
              }

              .buttonStyle {
                background-color: theme-color('secondary');

                padding: 0.5vw 2vw;

                font-size: 1.2vw;

                .telegramIcon {
                  margin-right: 1vw;
                }
                @media (max-width: 991px) {
                  padding: 2.5vw 8vw;

                  font-size: 4.2vw;

                  border-radius: 50px;
                }
              }
            }
          }
          @media (max-width: 991px) {
            padding: 10vw 6vw;
          }
        }
      }
      &:nth-child(2) {
        margin-bottom: 10vw;
      }

      @media (max-width: 991px) {
        width: 100%;
        max-width: 100%;

        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .footerText {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
  }
}
