.reservationPage {
  .rowSection {
    .infoCol {
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .infoListGroup {
        max-width: 90%;
        width: 100%;
        .infoListItem {
          background: transparent;
          display: flex;
          border-bottom: 1px solid theme-color('secondary');

          padding: 5em 4em 2em 0em;
          &:nth-child(1) {
            padding-top: 0;
          }
          h1 {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            &::before {
              font-size: 20vw;
              content: '\2022';
              color: theme-color('primary');
              font-weight: bold;
              display: inline-block;
              // width: 1em;
              // margin-left: -1em;
            }
          }
          p {
            font-size: 35px;
            line-height: 40px;
            margin-left: 4.5vw;

            max-width: 70%;
          }
        }
      }
    }
  }

  .venues {
    background-color: #faf5ef;

    &-heading {
      grid-column: 2/9;
    }

    &-txt {
      grid-column: 9/12;
      align-self: end;
    }

    &-items {
      position: relative;
      grid-column: 2/12;
    }

    &-item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      border-bottom: 1px solid #1f1f1f;
      // cursor: pointer;
      padding-bottom: 4rem;
      padding-top: 4rem;
    }

    & h6 {
      letter-spacing: 2px;
      pointer-events: none;
    }

    & h2 {
      pointer-events: none;
    }

    &-img {
      position: fixed;
      top: 0;
      left: 0;
      width: 70%;
      max-width: 400px;
      // height: 70%;
      background-size: cover;
      // background-image: url("/img/venue-1.jpg");
    }

    &-img-wrap {
      width: 30vw;
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
      pointer-events: none;
      visibility: hidden;
      padding-bottom: 25vw;
    }
  }

  .imageHovered {
    z-index: 11;
  }

  html,
  body {
    height: auto;
  }
  .panel {
    height: 100vh;
  }

  .block {
    height: 100vh;
  }
}
