.interviewPage {
  .rowSection {
    height: 100%;

    .videoCol {
      height: 100%;

      padding: 0;

      // display: flex;
      // justify-content: center;
      // align-items: center;

      .reactPlayer {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;

        display: flex;
        justify-content: center;
        align-items: center;

        .reactPlayerImg {
          position: absolute;
          width: 100%;
          height: 100%;
        }
      }

      .button-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      // .player-wrapper {
      //   position: relative;
      //   padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
      //   &:hover .homePlayContainer .homePlaySquare {
      //     transform: rotate(90deg);

      //     .homePlayIcon {
      //       transform: rotate(-90deg);
      //     }
      //   }
      // }

      &.videoColButton {
        padding-top: 2em;
      }
    }
  }
}
