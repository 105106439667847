.vslPage {
  .RootGuide {
    width: 100%;
    padding-top: 7.5vw;
    padding-bottom: 7.5vw;
    // overflow: hidden;

    justify-content: center;
    .rowSection {
      max-width: 100%;
    }
  }
}
