.vslPage {
  .rowSection {
    height: 100%;

    .paymentCol {
      display: flex;
      justify-content: center;
      align-items: center;

      .titleWarpper {
        position: relative;
        text-align: right;
        .paymentTitleStar {
          position: relative;
          margin-right: 4vw;

          width: 3.3vw;

          @media (max-width: 991px) {
            width: 9vw;
          }
        }
        .titleWrapperElementImg {
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
      }
      h4 {
        margin-top: 1em;
      }
    }
    .paymentCardsCol {
      margin-top: 4em;

      .card-deck {
        @media (min-width: 992px) {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .cardStyle {
        border: 1px solid theme-color('secondary');
        // background-color: theme-color('cards') !important;

        @media (max-width: 991px) {
          margin-bottom: 10vw;
        }
      }

      .paymentCardHeader {
        text-align: center;
      }
      .paymentCardBody {
        text-align: center;

        padding-top: 4em;
        padding-bottom: 3em;
      }
      .paymentCardFooter {
        display: flex;
        justify-content: center;
        align-items: center;
        .buttonStyle {
          border: none;
          background-color: theme-color('primary');
        }
      }
      @media (max-width: 1199px) {
        .cardStyle {
          margin-bottom: 2vw;
        }
      }
      @media (max-width: 991px) {
        .cardStyle {
          margin-bottom: 5vw;
        }
      }
    }
  }
}
