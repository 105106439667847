.vslPage {
  .RootVsl {
    width: 100%;
    padding-top: 5vw;
    padding-bottom: 5vw;

    justify-content: center;

    .rowSection {
      display: flex;
      justify-content: center;
      align-items: center;
      // max-width: 100%;
    }
  }
}
