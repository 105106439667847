.vslPage {
  .rowSection {
    height: 100%;

    .advantagesRowSection {
      &:nth-child(1) {
        margin-bottom: 5vw;
      }
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .advantagesCol {
        display: flex;
        justify-content: center;
        align-items: center;

        h1 {
          position: relative;
          text-align: left;
          .advantagesTitleStar {
            position: absolute;
            left: 0;
            top: 0;

            width: 5vw;
          }
        }
      }
      .advantagesCardsCol {
        max-width: 80%;
        margin-top: 4em;

        @media (max-width: 991px) {
          & {
            max-width: 100%;
          }
        }

        .advantagesCardHeader {
          text-align: center;
        }
        .advantagesCardBody {
          text-align: left;

          // padding-top: 4em;
          // padding-bottom: 3em;
          margin-bottom: 5em;
        }
        .advantagesCardFooter {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          .beforePrice {
            display: flex;
            justify-content: flex-end;
          }
          .saveText {
            text-align: right;
          }
          .advantagesCardFooterDiv {
            // display: flex;
            // justify-content: flex-end;
            text-align: right;
            position: relative;
            ul {
              list-style: none;

              // width: 60%;
              li {
                display: flex;
                text-align: right;
                // align-items: center;
                &::before {
                  content: '';
                  font-weight: bold;
                  display: inline-block;
                  width: 7vw;
                  height: 7vw;
                  max-width: 30px;
                  max-height: 30px;
                  margin-right: 1em;
                  background-image: url('../../../imgs/icons/asterisk.svg');
                  background-size: contain;
                  background-repeat: no-repeat;
                  background-position: center;
                }
                h5 {
                  font-style: italic;
                }
              }
            }
            .bonusIcon {
              width: 50%;
              max-width: 135px;
              position: relative;
              top: -3em;
            }
          }
          @media (max-width: 991px) {
            & {
              padding: 0;
              ul {
                padding-left: 0;
              }
            }
          }
        }
        .cardStyle {
          &:nth-child(1) {
            // height: fit-content;
            position: relative;
            // top: -10vw;
            right: -2vw;
          }
          &:nth-last-child(1) {
            height: fit-content;
            position: relative;
            top: -5vw;
            left: -2vw;
          }
        }
        @media (max-width: 991px) {
          .cardStyle {
            &:nth-child(1) {
              // height: fit-content;
              position: relative;
              // top: -10vw;
              right: 0;

              margin-bottom: 15px;
            }
            &:nth-last-child(1) {
              position: relative;
              top: 0;
              left: 0;
            }
          }
        }
      }
    }
  }
}
