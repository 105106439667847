.reservationPage {
  #homeCanvas {
    position: absolute;
    // top: 0;
    // left: 0;
    z-index: -1;
    width: auto;
    height: 100vh;

    opacity: 0.5;

    @media (max-width: 991px) {
      & {
        height: auto;
        width: 100%;
        top: 0;
      }
    }
  }

  .modelDividerVideoWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    &.modelDividerDark {
      background-color: #0b0a0c;
    }

    video {
      transition: 1s;
      // width: 50%;
      height: 100%;
      // min-height: 0;
      max-height: 100%;
      @media (max-width: 991px) {
        height: auto;

        max-height: none;
        width: 100%;
      }
    }
  }
}
