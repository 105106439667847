.vslPage {
  .RootHome {
    width: 100%;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;

    padding-top: 127px;

    // background-color: #080708;
    .rowSection {
      z-index: 3;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @media (max-width: 991px) {
      & {
        height: auto;
        padding-top: 72px;
      }
    }

    .homePlaySquare {
      border: 1px solid theme-color('secondary') !important;
      background-color: transparent !important;

      svg {
        path {
          fill: theme-color('secondary') !important;
        }
      }
    }
  }

  .ball {
    width: 50px;
    height: 50px;
    position: fixed;
    top: 0;
    left: 0;
    border: 1px solid #fff;
    border-radius: 50%;
    z-index: 999999999999;
    pointer-events: none;
    user-select: none;

    mix-blend-mode: difference;
  }
}
