.vslPage {
  .rowSection {
    .secureRow {
      max-width: 100%;
      width: 100%;
      .secureCol {
        text-align: center;
        &.secureColTitle {
          display: flex;
          justify-content: center;
          align-items: center;
          @media (max-width: 991px) {
            margin-bottom: 15vw;
          }
        }

        .magnetic-wrap {
          width: fit-content;
        }

        .secureStarImg {
          position: absolute;
          top: -4vw;
          right: 4vw;
          width: 8vw;

          @media (max-width: 991px) {
            width: 30vw;
            top: -15vw;

            right: 0;
          }
        }

        .cardStyle {
          a {
            color: theme-color('primary');
          }
          @media (max-width: 991px) {
            padding-top: 10vw;
            .signSecure {
              width: 100%;
              text-align: center;
            }
          }
        }
      }
      @media (max-width: 991px) {
        & {
          max-width: 100%;
        }
      }
    }
  }
}
