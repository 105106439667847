/* BACKGROUND ROW */
/* .Root {
    position: relative;
} */
.RootFooter {
  // background-color: theme-color('bgColor');
  height: auto;
  // padding-top: 7.5vw;
  // padding-bottom: 5vw;
  .RootFooter-inside {
    display: flex;
    justify-content: center;
    align-items: center;
    .RootFooter-inside-div {
      max-width: 80%;
    }
    &.RootFooter-inside-face {
      margin-top: 6vw;
      @media (max-width: 991px) {
        margin-top: 15vw;
        // margin-bottom: 2vw;
      }
    }
  }
  @media (max-width: 991px) {
    & {
      padding-bottom: 7.5vw;
    }
    .RootFooter-inside,
    .RootFooter-inside-brand {
      display: flex;
      justify-content: center;
      align-items: center;
      .RootFooter-inside-div {
        width: 85%;
      }
    }
  }

  /* CONTENT */
  .RootFooter-inside {
    /* position: relative; */
    width: 60%;
    margin: 0 auto;
    // display: flex;
    // justify-content: center;
    text-align: center;
    .footerPoliticsListWrap {
      @media (max-width: 991px) {
        margin-top: 10vw;
      }
      .footerPoliticsList {
        // padding: 0.1em 0;
        display: block;

        color: theme-color('secondary');
        transition: color 0.3s;
        &:hover {
          color: theme-color('primary');
        }
        // FONT
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
        line-height: 20px;

        letter-spacing: 0.085em;

        @media (max-width: 991px) {
          text-align: center;
          // FONT
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 300;
          font-size: 4vw;
          line-height: 6vw;

          letter-spacing: 0.085em;
        }
      }
    }

    @media (max-width: 991px) {
      & {
        text-align: left;
      }
    }
  }
  .RootFooter-inside-brand {
    margin: 6vw 0;

    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 991px) {
      img {
        width: 85%;
      }
    }
  }
}
