.vslPage {
  .rowSection {
    .reservationRow {
      max-width: 70%;
      width: 100%;
      .reservationCol {
        text-align: center;
        &.reservationColTitle {
        }

        .magnetic-wrap {
          width: fit-content;
        }
      }
      @media (max-width: 991px) {
        & {
          max-width: 100%;
        }
      }
    }
  }
}
