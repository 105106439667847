/* BACKGROUND ROW */
/* .Root {
    position: relative;
} */
.reservationPage,
.thanksPageRow {
  .RootFooter {
    // background-color: theme-color('bgColor');
    height: auto;
    padding-top: 7.5vw;
    padding-bottom: 5vw;

    @media (max-width: 991px) {
      & {
        padding-bottom: 7.5vw;
      }
      .RootFooter-inside,
      .RootFooter-inside-brand {
        display: flex;
        justify-content: center;
        align-items: center;
        .RootFooter-inside-div {
          width: 85%;
        }
      }
    }

    /* CONTENT */
    .RootFooter-inside {
      /* position: relative; */
      max-width: 90%;
      margin: 0 auto;
      // display: flex;
      // justify-content: center;
      text-align: center;
      @media (max-width: 991px) {
        & {
          text-align: left;
        }
      }
    }
    .RootFooter-inside-brand {
      margin: 6vw 0;

      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 991px) {
        img {
          width: 85%;
        }
      }

      &:nth-last-child(1) {
        margin: 0;
      }
    }
  }
}

.poweredBy {
  text-align: center;
  font-family: 'Whyte Inktrap';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.085em;

  a {
    color: theme-color('primary');
  }
  // @media (max-width: 1600px) {
  //   font-size: 1vw;
  //   line-height: 1.5vw;
  // }
  @media (max-width: 991px) {
    margin-top: 2em;
    font-size: 3.5vw;
    line-height: 4vw;
  }
}
