.interviewPage {
  .rowSection {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // overflow-x: hidden;
    .homeCol {
      height: 100%;

      padding: 0;

      display: flex;
      justify-content: center;
      align-items: center;
      .player-wrapper {
        position: relative;
        // padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
        width: 60%;
        max-width: 1440px;

        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 991px) {
          & {
            width: 100%;
          }
        }
      }

      // .nftPlayer {
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      // }

      @media (max-width: 991px) {
        & {
          height: auto;
        }
        &:nth-child(3) {
          padding-left: 0;
        }
        &.homeColForm {
          justify-content: center;
        }
      }

      .customListIn {
        padding: 0;
        list-style: none;

        width: 60%;
        margin: 3em auto 0 auto;

        display: flex;
        justify-content: center;
        align-items: center;
        li {
          display: flex;
          align-items: center;
          text-align: left;

          font-family: 'Roboto';
          font-style: normal;
          font-weight: normal;
          font-size: 1.1vw;
          line-height: 1.6vw;
          &::before {
            content: '';
            font-weight: bold;
            display: inline-block;
            width: 7vw;
            height: 7vw;
            max-width: 30px;
            max-height: 30px;
            margin-right: 1em;
            background-image: url('../../../../imgs/icons/asterisk.svg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
          }
          @media (max-width: 991px) {
            font-size: 3.1vw;
            line-height: 3.6vw;
          }
        }
      }

      .homeJumbo {
        padding: 2em 0;
        text-align: center;
        width: 60%;
        ul {
          padding: 0;
          list-style: none;

          width: 60%;
          margin: 3em auto 0 auto;
          li {
            display: flex;
            align-items: center;
            &::before {
              content: '';
              font-weight: bold;
              display: inline-block;
              width: 7vw;
              height: 7vw;
              max-width: 30px;
              max-height: 30px;
              margin-right: 1em;
              background-image: url('../../../../imgs/icons/asterisk.svg');
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
            }
          }
        }
        h2 {
          margin-top: 1em;
        }

        @media (max-width: 991px) {
          & {
            width: 100%;
          }
          .titleWarpperHome {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          ul {
            width: 100%;
          }
        }
      }

      .treeImg {
        height: 100%;
      }
      &.homeColRotate {
        margin-bottom: 5vw;
        .mobileRotationImg {
          width: 8vw;
        }
      }
      &.homeColButton {
        height: 0;
        transition: 1s;
        margin-top: 2em;
        margin-bottom: 2em;
        transition: 1s;
        transform: scale(0);
        .countDownDiv {
          display: flex;
          justify-content: center;
          align-items: center;
          width: fit-content;

          border: 1px solid theme-color('secondary');
          padding: 1em 2em;
          @media (max-width: 991px) {
            padding: 3vw 2vw;
          }
          h1 {
            margin: 0 0.2em;
          }
          h3 {
            margin-bottom: 0;
          }
        }
        @media (max-width: 991px) {
          margin-top: 7vw;
          margin-bottom: 7vw;
        }
        .button-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;

          .magnetic-wrap {
            @media (max-width: 991px) {
              margin-left: 0;
              margin-right: 0;
            }
          }
        }
        .buttonStyle {
          background-color: theme-color('primary');
          // span {
          color: theme-color('bgColor');

          @media (max-width: 991px) {
            font-size: 3vw !important;
            line-height: 3.5vw;
          }
          // }
        }
        .js-magnetic-area:hover ~ .buttonStyle {
          background-color: transparent !important;
          color: theme-color('secondary') !important;
        }
      }
    }

    .readMoreContainer {
      position: absolute;
      z-index: 10;
      bottom: 10px;

      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 991px) {
        & {
          position: relative;
          margin: 20vw 0 10vw 0;
        }
      }
      .readMoreImg {
        width: 120px;
        height: 120px;

        display: flex;
        justify-content: center;
        align-items: center;

        pointer-events: none;
      }
      .downArrowIcon {
        position: absolute;
      }
      @media (max-width: 991px) {
        .readMoreImg {
          width: 20vw;
          height: 20vw;
          max-width: 120px;
          max-height: 120px;
        }
        .downArrowIcon {
          width: 5vw;
          max-width: 30px;
        }
      }
    }
  }

  #interviewIframe {
    iframe {
      #document {
        display: none;
      }
    }
  }

  .form41Wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(0, 0, 0, 0.8);
    .fomr41TitleWrap {
      display: flex;
      align-items: center;
      margin-bottom: 2vw;
      position: relative;
      .lockImg {
        height: 100%;
      }

      .fomr41Title {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: normal;
        font-size: 1.2vw;
        line-height: 1.5vw;
        margin-left: 1em;

        color: theme-color('primary');

        position: relative;
        margin-bottom: 0;
        @media (max-width: 991px) {
          font-size: 3.2vw;
          line-height: 3.5vw;
        }
      }
    }
    ._button-wrapper {
      padding-top: 0;
    }

    #_form_41_submit {
      @media (max-width: 991px) {
        font-size: 3vw;
        line-height: 3.5vw;

        padding: 1.5vw 2.5vw !important;
      }
    }

    // #_form_41_submit {
    //   position: relative;
    //   margin: 0 auto;
    //   width: 9vw;
    //   max-width: 130px;
    //   height: 9vw;
    //   max-height: 130px;
    //   border-radius: 50% !important;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;

    //   background-color: theme-color('primary');
    //   border: none;

    //   color: theme-color('bgColor');

    //   // FONT
    //   font-family: 'Whyte Inktrap';
    //   font-style: normal;
    //   font-weight: bold;
    //   font-size: 15px;
    //   line-height: 20px;

    //   @media (max-width: 1600px) {
    //     & {
    //       font-size: 1.3vw;
    //       line-height: 1.5vw;
    //     }
    //   }

    //   &.reservationEffect {
    //     transition: background-color 0.3s, border 0.3s, color 0.3s;

    //     &:hover {
    //       background-color: transparent !important;

    //       border: 1.5px solid theme-color('primary') !important;

    //       color: theme-color('textColor') !important;
    //     }
    //   }

    //   @media (max-width: 991px) {
    //     & {
    //       width: 15vw;
    //       height: 15vw;
    //       max-width: 30vw;
    //       max-height: 30vw;

    //       // FONT
    //       font-size: 3.5vw;
    //       line-height: 4vw;
    //     }
    //     &:hover,
    //     &:active {
    //       background-color: transparent !important;

    //       border: 1.5px solid theme-color('primary') !important;

    //       color: theme-color('textColor') !important;
    //     }
    //   }

    //   &:hover {
    //     cursor: none;
    //   }
    //   &:focus {
    //     outline: none;
    //   }
    // }

    #_form_41_submit::after {
      content: '';
      height: 64px;
      width: 100%;

      // background-image: url("../../../../assets/img/icons/olivialucia-boton.svg");
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100% !important;

      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
    }
  }
}

.bounceEffect {
  transform: scale(1) !important;
  animation: bounce 2s 2;
  height: auto !important;
}

@keyframes bounce {
  0% {
    animation-timing-function: ease-in-out;
    transform: scale(1);
  }
  20% {
    transform: scale(1.4);
  }
  40% {
    animation-timing-function: ease-in-out;

    transform: scale(1);
  }
  60% {
    transform: scale(1.4);
  }
  80% {
    animation-timing-function: ease-in-out;

    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
