.rowSection {
  width: 100%;
  max-width: 90%;
  @media (max-width: 991px) {
    & {
      max-width: 80%;
    }
  }
}

.rowBlackFridayBG {
  background: url('../../imgs/blackfriday/bglines.png') 0px 0px no-repeat;
  background-size: auto 100% !important;
}

.rowBlackFridayBG2 {
  background: url('../../imgs/blackfriday/bglines2.png') 0px 0px repeat-y;
  background-size: auto 100% !important;
}

#interviewIframe iframe {
  transition: 0.2s filter linear;
}

#interviewIframe.pausedMode iframe {
  filter: blur(1.5rem);
}
