//  CARDS
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
) !default;

$grid-gutter-width: 30px !default;

$cards-per-line: (
  xs: 1,
  sm: 1,
  md: 1,
  lg: 2,
  xl: 2,
);
$cards-per-line-payment: (
  xs: 1,
  sm: 1,
  md: 1,
  lg: 2,
  xl: 3,
);

@each $name, $breakpoint in $grid-breakpoints {
  @media (min-width: $breakpoint) {
    .card-deck .card.cardConfig {
      flex: 0 0
        calc(#{100 / map-get($cards-per-line, $name)}% - #{$grid-gutter-width});
    }
    .card-deck .card.cardConfigPayment {
      flex: 0 0
        calc(
          #{100 / map-get($cards-per-line-payment, $name)}% - #{$grid-gutter-width}
        );
    }
  }
}

.cardStyle {
  // background-color: theme-color('cards');

  background-color: theme-color('bgColor');
  border: 1px solid theme-color('secondary');
  border-radius: 20px;

  padding: 4em;
  @media (max-width: 1600px) {
    padding: 4vw;
  }
  @media (max-width: 991px) {
    padding: 5vw;
    .card-body {
      // padding: 0;
    }
  }
}

// DECK ALONE TO CENTER THE CARD
.cardDeckAlone {
  display: flex;
  justify-content: center;
  align-items: center;
}

// CARD STAR
.cardStar {
  // display: none;
  width: 90px;
  height: 90px;
  position: absolute;
  // left: -45px;
  @media (max-width: 991px) {
    width: 8vw;
    height: 8vw;
  }
}
