.vslPage {
  .rowSection {
    .momCitesRowSection {
      width: 100%;
      padding: 5vw 0;

      // display: flex;
      // justify-content: flex-start;
      // align-items: center;

      .momCitesRowSectionTitle {
        margin-bottom: 6em;
      }
      .momCitesCol {
        display: flex;
        justify-content: center;
        align-items: center;
        @media (min-width: 992px) {
          // padding-left: 0;
          padding-left: 5%;
        }
        &.momCitesColmg {
          justify-content: flex-end;
        }
        .momCitesColDiv {
          border-bottom: 1px solid theme-color('textColor');
          width: 80%;
          p {
            margin-bottom: 2em;
            //   &:nth-child(2),
            //   &:nth-last-child(1) {
            //     margin-bottom: 0;
            //   }
            span.comillasContainer {
              position: relative;
              font-family: 'Whyte Inktrap';
              font-weight: bold;
              text-transform: uppercase;

              padding-top: 2em;
              //   font-style: normal;
              //   font-weight: normal;
              //   font-size: 25px;
              //   line-height: 20px;

              //   &::before {
              //     content: '<div></div>';
              //     width: 100px;
              //     height: 100px;
              //   }
            }
          }
          @media (max-width: 991px) {
            width: 90%;
            padding-left: 0;
          }
        }
        .momCitesColmgDiv {
          position: relative;
          .momCitesImgs {
            width: 80%;
            &.momCitesImg3 {
              position: absolute;
              width: 50%;
              right: 0;
              top: -5vw;
            }
            @media (max-width: 991px) {
              width: 90%;
            }
          }
          @media (max-width: 991px) {
            &.momCitesImgsColWrap {
              display: block;
              text-align: center;
              margin-top: 0;
              .momCitesImg3 {
                position: relative;
                right: auto;
                top: 10vw;
              }
            }
          }
          // &:nth-last-child(1) {
          //   padding-top: 10vw;
          // }
          @media (max-width: 991px) {
            display: flex;
            justify-content: center;
            align-items: center;

            margin: 10vw 0 0 0;
          }
        }
      }

      .momCitesColTitle {
        justify-content: flex-start;
      }

      @media (max-width: 991px) {
        & {
          width: 100%;
        }
        &.momCitesRowSectionTitle {
          // margin-bottom: 3em;
          .momCitesColTitle {
            margin-bottom: 5vw;
          }
        }
      }
    }
  }
}
