/*************************************************************************
*
* Customize Bootstrap 4 with our new built-in Sass variables for 
* global style preferences for easy theming and component changes.
*
* Please read more at https://getbootstrap.com/docs/4.0/getting-started/theming/
* 
*************************************************************************/

@debug rgba(#fff, 0.8);

$theme-colors: (
  primary: #d6bd77,
  secondary: #e1e1e1,
  bgColor: #080708,
  bgColor2: #0b0b0b,
  cards: #0f0f0f,
  textColor: #e1e1e1,
);
