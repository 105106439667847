.reservationPage {
  .rowSection {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // overflow-x: hidden;
    .homeCol {
      height: 100%;

      padding: 0;

      display: flex;
      justify-content: flex-start;
      align-items: center;

      &:nth-child(1) {
        //   padding-right: 100px;
      }
      &:nth-child(3) {
        padding-left: 100px;
      }
      &.homeColForm {
        justify-content: flex-end;
      }

      @media (max-width: 991px) {
        & {
          height: auto;
        }
        &:nth-child(3) {
          padding-left: 0;
        }
        &.homeColForm {
          justify-content: center;
        }
      }

      .homeJumbo {
        padding: 2em 0;

        ul {
          margin-top: 3em;
          padding: 0;
          list-style: none;

          width: 60%;
          li {
            display: flex;
            align-items: center;
            &::before {
              content: '';
              font-weight: bold;
              display: inline-block;
              width: 7vw;
              height: 7vw;
              max-width: 30px;
              max-height: 30px;
              margin-right: 1em;
              background-image: url('../../../../imgs/icons/asterisk.svg');
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
            }
          }
        }

        @media (max-width: 991px) {
          .titleWarpperHome {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          ul {
            width: 100%;
          }
        }
      }

      .treeImg {
        height: 100%;
      }
    }

    .homePlayContainer {
      z-index: 10;
      // cursor: pointer;
      position: absolute;
      width: fit-content;
      height: fit-content;
      pointer-events: all;
      @media (max-width: 991px) {
        & {
          position: relative;
          padding: 5vw 0;
          margin: 0 0 2em 0;
        }
      }

      .homePlaySquare,
      .homePlayIcon {
        transition: transform 0.3s;
      }
      .homePlaySquare {
        pointer-events: all;

        border: 1px solid theme-color('secondary');
        background-color: transparent;
        width: 120px;
        height: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: rotate(45deg);
        &:hover {
          transform: rotate(90deg);

          .homePlayIcon {
            transform: rotate(-90deg);
          }
        }

        .homePlayIcon {
          transform: rotate(-45deg);
          path {
            fill: theme-color('secondary');
          }
        }
        @media (max-width: 1600px) {
          & {
            width: 8vw;
            height: 8vw;
            max-width: 120px;
            max-height: 120px;

            svg {
              width: 2vw;
            }
          }
        }
        @media (max-width: 991px) {
          & {
            width: 15vw;
            height: 15vw;
            max-width: 120px;
            max-height: 120px;

            svg {
              width: 4vw;
            }
          }
        }
      }
    }

    .readMoreContainer {
      position: absolute;
      z-index: 10;
      bottom: 10px;

      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 991px) {
        & {
          position: relative;
          margin: 20vw 0 10vw 0;
        }
      }
      .readMoreImg {
        width: 120px;
        height: 120px;

        display: flex;
        justify-content: center;
        align-items: center;

        pointer-events: none;
      }
      .downArrowIcon {
        position: absolute;
      }
    }
  }
}
