$magnetic-size: 2em;
$magnetic-size2: 2em;

a:link {
  text-decoration: none;
}

button,
a {
  outline: none !important;
}

.button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.BFlogo {
  position: relative;
  top: -60px;
  left: 30px
}
@media (max-width: 991px) {
  .buttonStyleBF {
    font-size: 16px !important;
  }

  .BFlogo {
    top: 80px;
    left: -90px;
  }
}

.buttonStyle {
  background: transparent;
  padding: 1em 2em;

  box-shadow: none;
  border: 1px solid theme-color('secondary');
  border-radius: 15px;

  // text
  color: theme-color('textColor');
  font-family: 'Whyte Inktrap';
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;

  text-transform: uppercase;

  transition: background-color 0.3s, color 0.3s;

  @media (max-width: 1600px) {
    & {
      // text
      color: theme-color('textColor');
      font-family: 'Whyte Inktrap';
      font-style: normal;
      font-weight: bold;
      font-size: 1.3vw;
      line-height: 1.5vw;
    }
  }
  @media (max-width: 991px) {
    & {
      font-size: 4.3vw;
      line-height: 4.5vw;
    }
  }

  &.buttonTextBeforeAfter {
    font-family: 'Cosi Times';
    font-weight: normal;

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 35px;
    line-height: 40px;
    &::before,
    &::after {
      opacity: 0.1;
      position: absolute;
    }
    &::before {
      content: 'ilustrador';
      top: calc(-35px / 2);
      @media (max-width: 1600px) {
        top: calc(-1.3vw / 2);
      }
      @media (max-width: 991px) {
        top: calc(-4.3vw / 2);
      }
    }
    &::after {
      content: 'diseñador';
      bottom: calc(-35px / 2);
      @media (max-width: 1600px) {
        bottom: calc(-1.3vw / 2);
      }
      @media (max-width: 991px) {
        bottom: calc(-4.3vw / 2);
      }
    }
  }

  &:hover {
    background: theme-color('secondary');
    color: theme-color('bgColor');
  }
}

.magnetic-wrap {
  display: inline-block;
  position: relative;
}

// .my-burger {
//   width: 4rem;
//   height: 4rem;
//   border-radius: 2rem;
//   background: #ffb31a;
// }

.magnetic-size {
  position: absolute;
  bottom: -$magnetic-size;
  top: -$magnetic-size;
  left: -$magnetic-size;
  right: -$magnetic-size;
  z-index: 1; // The area has to be on top
  border-radius: 15px;
  // border: 2px dashed #333; // Guideline to visualize the area
}

.size1 {
  margin: $magnetic-size;
}
.size2 {
  margin: $magnetic-size2;
}

.wrong {
  background: rgba(red, 0.2);
  bottom: -$magnetic-size2;
  top: -$magnetic-size2;
  left: -$magnetic-size2;
  right: 0;
  border-radius: 0;
}

// RESERVATION BUTTONS

.reservationButton {
  max-width: 200px;
  max-height: 200px;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: theme-color('primary');
  border: none;

  color: theme-color('bgColor');

  @media (max-width: 1600px) {
    & {
      width: 13vw;
      height: 13vw;
    }
  }

  @media (max-width: 991px) {
    &.buttonStyle {
      font-size: 4vw;
      line-height: 4.5vw;
    }
    & {
      width: 30vw;
      height: 30vw;
      padding: 3vw;
      transition: background-color 0.3s, border 0.3s, color 0.3s;
    }
    &:hover,
    &:active {
      background-color: transparent;

      border: 1.5px solid theme-color('primary');

      color: theme-color('textColor');
    }
  }
}
.reservationEffect {
  transition: background-color 0.3s, border 0.3s, color 0.3s;
}
.js-magnetic-area:hover ~ .secondaryEffect {
  background-color: theme-color('primary') !important;

  color: theme-color('bgColor') !important;
}
.js-magnetic-area:hover ~ .reservationEffect {
  background-color: transparent !important;

  border: 1.5px solid theme-color('primary') !important;

  color: theme-color('textColor') !important;
}
