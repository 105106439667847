.vslPage {
  .RootFaq {
    width: 100%;

    padding-top: 5vw;
    padding-bottom: 5vw;

    position: relative;

    justify-content: center;

    .rowSection {
      // display: flex;
      // justify-content: center;
      // align-items: center;
      @media (max-width: 991px) {
        max-width: 90%;
      }
    }
  }
}
