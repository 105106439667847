@import './pages/home';
@import './pages/video';

@import './pages/components/homeSection';
@import './pages/components/videoSection';

@import './components/3dModels';
@import './components/customPlayer';

@import './base/Typo.scss';

.interviewPage {
  .RootFooter {
    // padding-top: 8vw;
    @media (max-width: 991px) {
      padding-top: 10vw;
    }
  }
}
