.reservationPage {
  .rowSection {
    .moreThanRowSection {
      width: 70%;

      &.moreThanRowSectionTitle {
        margin-bottom: 6em;
      }
      .moreThanCol {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .moreThanCardDeckAlone {
        position: relative;
        top: -30px;
      }
      .cardStyle {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 35px;
        margin-right: 35px;
        .card-body {
          text-align: center;
        }

        .moreThanCardStar {
          width: 150px;
          height: 150px;
          top: -75px;
          right: -65px;
          z-index: -1;
        }
      }
      @media (max-width: 991px) {
        .card-deck {
          display: block;
        }
        & {
          width: 80%;
          .moreThanCol {
            margin-bottom: 15px;
          }
          .cardStyle {
            // padding: ;
            width: 100%;
            margin-left: 0;
            margin-right: 0;
            z-index: 1;
            .moreThanCardStar {
              width: 15vw;
              height: 15vw;
              top: -7.5vw;
              right: -7.5vw;
              z-index: -1;
            }
          }
          .moreThanCardDeckAlone {
            display: block;
            top: 0;
          }
        }
      }
      @media (max-width: 991px) {
        & {
          width: 100%;
        }
        &.moreThanRowSectionTitle {
          margin-bottom: 3em;
          .moreThanColTitle {
            margin-bottom: 2em;
          }
        }
      }
    }
  }
}
