.vslPage {
  .rowSection {
    .vslCol {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 5%;

      ul {
        margin-top: 3em;
        padding: 0;
        list-style: none;

        width: 100%;
        li {
          display: flex;
          align-items: center;
          &::before {
            content: '';
            font-weight: bold;
            display: inline-block;
            width: 7vw;
            height: 7vw;
            max-width: 30px;
            max-height: 30px;
            margin-right: 1em;
            background-image: url('../../../imgs/icons/asterisk.svg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
          }
        }
      }
    }
    .vslRowVideo {
      width: 100%;
      margin-top: 5vw;
      margin-bottom: 3vw;

      .vslColVideo {
        width: 100%;
        height: 40vw;
        max-height: 80vh;
        background: #101010;

        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        .vslLoop {
          width: 100%;
          height: 100%;
          object-fit: cover;
          // top: 0;
          // left: 0;
          // position: absolute;
        }

        .homePlayContainer {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          pointer-events: auto;
          &:hover {
            .homePlaySquare {
              transform: rotate(90deg);

              .homePlayIcon {
                transform: rotate(-90deg);
              }
            }
          }
        }

        @media (max-width: 991px) {
          height: 50vw;
          .homePlayContainer {
            margin: 0;
          }
        }
      }
    }
    .vslRow {
      width: 70%;
      .button-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        .magnetic-wrap {
          width: fit-content;
        }
      }
    }

    // .vslColTitle {
    //   justify-content: flex-start;
    // }

    @media (max-width: 991px) {
      & {
        width: 100%;
      }
      &.vslRowSectionTitle {
        margin-bottom: 3em;
        .vslColTitle {
          margin-bottom: 2em;
        }
      }
    }
  }
}
