.vslPage {
  .rowSection {
    width: 100%;
    .guideSectionCol {
      &.guideSectionColTitle {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        h4 {
          margin-top: 2vw;
          text-transform: uppercase;

          @media (max-width: 991px) {
            margin-top: 6vw;

            font-size: 3.5vw;
            line-height: 3.7vw;
          }
        }
      }

      .sliderGuide {
        overflow: hidden;
        padding: 5em 0;
        .slick-list {
          overflow: visible;
        }

        .slick-track {
          display: flex;
          align-items: center;
        }
        .slick-dots {
          bottom: 0;
          li button:before {
            color: theme-color('secondary');
          }
        }

        @media (max-width: 991px) {
          padding: 3em 0 0 0;
          max-width: 80%;
          overflow: visible;

          .slick-list {
            padding: 3em 0;
            overflow: hidden;
          }
        }
      }

      .sliderGuide .item {
        position: relative;
        //  padding: 150px 80px;
        flex: 0 0 0;
        height: 100%;
        display: flex;
        align-items: center;
        line-height: 1.7;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        border-right: 1px solid rgba(0, 0, 0, 0.06);
        background-color: transparent;
        @media (max-width: 991px) {
          padding: 0 3vw;
        }

        .guideCards {
          font-size: 5rem;
          // width: 35vw;
          // max-width: 500px;
          // height: 25vw;
          // max-height: 600px;

          padding: 0.5em;
          margin: 0 2.5vw;
          // &.firstGuideCards {
          //   margin-left: 0;
          // }
          // &.lastGuideCards {
          //   margin-right: 0;
          // }

          flex-shrink: 0;
          display: flex;
          align-items: center;
          justify-content: center;

          .guideCardBody {
            // font-family: 'Roboto';
            // font-style: normal;
            // font-weight: normal;
            // font-size: 40px;
            // line-height: 45px;
            flex: inherit;
          }
          .bonusFont {
            font-family: 'Whyte Inktrap';
            font-style: normal;
            font-weight: bold;
            font-size: 0.8vw;
            line-height: 1vw;
            @media (max-width: 991px) {
              font-size: 1.8vw;
              line-height: 2vw;
            }
          }

          @media (max-width: 991px) {
            .guideCardBody {
              padding: 2vw;
            }
          }
        }
        .guideCards.active {
          color: orange;
        }
        .guideCardNumber {
          width: 5vw;
          max-width: 90px;
          height: 5vw;
          max-height: 90px;
          background-color: theme-color('bgColor');
          border: 1px solid theme-color('secondary');
          border-radius: 50%;

          position: absolute;
          top: calc(-5vw / 2);
          right: 25px;

          display: flex;
          justify-content: center;
          align-items: center;
        }

        @media (max-width: 991px) {
          .guideCards {
            font-size: 5rem;
            max-height: 100%;
            height: auto;
            max-width: 100%;
            width: 100%;

            padding: 5vw;
            margin: 0 !important;
            // &.firstGuideCards {
            //   margin-left: 0;
            // }
            // &.lastGuideCards {
            //   margin-right: 0;
            // }

            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .guideCardNumber {
            width: 10vw;
            height: 10vw;
            top: calc(-10vw / 2);
          }
          .cardWrap:nth-last-child(1) {
            padding-right: 5vw;
          }
        }
      }

      .sliderGuide .item:before {
        position: absolute;
        font-size: 100px;
        opacity: 0.13;
        font-weight: bold;
        z-index: -1;
        -webkit-transform: translate(-30px, -50px);
        transform: translate(-30px, -50px);
        line-height: 1;
      }

      .button-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .artistGuideH2 {
        width: 60%;
        margin: 3vw auto 0 auto;

        text-transform: none;

        text-align: center;
        @media (max-width: 991px) {
          margin-bottom: 15vw;
        }
      }
      @media (max-width: 991px) {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &.guideSectionColButton {
        margin-top: 5vw;
        @media (max-width: 991px) {
          margin-top: 2vw;
        }
      }
    }
  }
}
