.reservationPage {
  .RootGuide {
    width: 100%;
    padding-top: 1vw;
    margin-top: 50px;

    justify-content: center;
    @media (max-width: 991px) {
      & {
        padding-bottom: 7.5vw;
      }
    }
    .rowSection {
      max-width: 100%;
    }
  }
}
