// ACCORDION
.vslPage {
  .rowSection {
    .faqCol {
      @media (max-width: 991px) {
        // padding: 0;

        &.faqColCta {
          justify-content: center;
          align-items: center;
        }
      }
    }

    .accordionCardCourseContent {
      .customList {
        li {
          align-items: center;
          justify-content: space-between;
          position: relative;
          padding-left: 25px;

          font-weight: 300;
          margin-bottom: 0.5em;

          @media (max-width: 991px) {
            margin-bottom: 1.5em;
          }

          &::before {
            position: absolute;
            left: 0;
            background-image: url('../../../imgs/courseContent/listPlay.svg');
          }
          .spanTimeLock {
            display: flex;
            justify-content: center;
            align-items: center;
            .courseContentLockIcon {
              margin-left: 1vw;
              @media (max-width: 991px) {
                margin-left: 3vw;
              }
            }

            @media (max-width: 991px) {
              .lessonTime {
                width: max-content;
              }
            }
          }

          &.liSubtitle {
            font-size: 1.5vw;
            line-height: 1.5vw;

            @media (max-width: 991px) {
              font-size: 3vw;
              line-height: 3vw;
            }
          }

          @media (max-width: 991px) {
            .lessonTitle {
              padding-right: 2em;
            }
          }
        }
      }
      .accordionBody {
        .accordionText {
          span {
            color: theme-color('secondary');
          }
        }
      }
      .accordionToggleDiv {
        justify-content: space-between !important;

        span.totalTime {
          font-family: 'Whyte Inktrap';
          font-style: normal;
          font-weight: 900;
          font-size: 1.5vw;
          line-height: 1.5vw;
          @media (max-width: 991px) {
            font-size: 3vw;
            line-height: 3vw;
          }
        }
        h3 {
          font-size: 2vw !important;
          line-height: 2vw !important;

          @media (max-width: 991px) {
            font-size: 4vw !important;
            line-height: 4vw !important;
          }
        }
      }
    }
  }
}
