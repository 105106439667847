// ACCORDION
.vslPage {
  .rowSection {
    .faqCol {
      @media (max-width: 991px) {
        // padding: 0;
      }
    }
    .accordionFaq {
      .accordionCardSpace {
        background-color: transparent;

        margin: 3em 0;

        @media (max-width: 991px) {
          &:nth-last-child(1) {
            margin-bottom: 0;
          }
        }
      }
    }
    .accordionCardTitle {
      background: transparent !important;
      &.accordionCardTitle2 {
        margin-top: 3em;
      }
      * {
        background: transparent !important;
      }
    }
    .accordionCard {
      position: relative;
      border-bottom: 1px solid theme-color('secondary');

      padding-left: 5vw;

      &.accordionCardFaq {
        .accordionToggle {
          padding: 3em 2em 4em 2em;
          .accordionToggleDiv {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .accordionToggleIcon {
              position: absolute;
              left: 0;

              padding: 2em;
              background-color: theme-color('primary');
              border-radius: 50%;
              max-width: 80px;
              max-height: 80px;
              width: 5vw;
              height: 5vw;

              display: flex;
              justify-content: center;
              align-items: center;

              font-size: 18px;

              svg,
              path {
                background: transparent !important;
                fill: theme-color('bgColor');
              }
              @media (max-width: 991px) {
                padding: 5vw;
              }
            }
            h3 {
              font-family: 'Cosi Times';
              font-style: normal;
              font-weight: normal;
              font-size: 3vw;
              line-height: 3.5vw;

              margin-bottom: 0;
              @media (max-width: 991px) {
                font-size: 5vw;
                line-height: 100%;
              }
            }
          }
          @media (max-width: 991px) {
            padding: 3vw 2vw 7vw 8vw;
          }
        }
      }
      &.accordionCardVSL {
        .accordionToggle {
          padding: 2em;
          .accordionToggleDiv {
            display: flex;
            justify-content: space-between;
            align-items: center;

            h3 {
              font-family: 'Helvetica';
              font-style: normal;
              font-weight: bold;
              font-size: 16px;
              line-height: 20px;

              color: theme-color('textColor');

              margin-bottom: 0;
            }
          }
        }
      }
      .accordionBody {
        padding: 1em 2em 2em 2em;
        .accordionText {
          span,
          a {
            color: theme-color('primary');
          }
          p {
            opacity: 0.8;
          }
        }
        @media (max-width: 991px) {
          padding: 2vw 3vw 3vw 3vw;
        }
      }
    }
  }
}
