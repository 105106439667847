.reservationPage {
  .rowSection {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .paymentCol {
      display: flex;
      justify-content: center;
      align-items: center;

      h1 {
        position: relative;
        text-align: right;
        .paymentTitleStar {
          position: absolute;
          left: 0;
          top: 0;

          width: 5vw;
        }
      }
    }
    .paymentCardsCol {
      max-width: 60%;
      margin-top: 4em;

      .paymentCardHeader {
        text-align: center;
      }
      .paymentCardBody {
        text-align: center;

        padding-top: 4em;
        padding-bottom: 3em;
      }
      .paymentCardFooter {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
