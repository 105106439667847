.vslPage {
  .noticesSectionCol {
    .slick-track {
      align-items: unset !important;
    }
    .slick-slide {
      height: inherit;
      flex-flow: row wrap;

      & > div {
        height: 100%;
        .cardStyle {
          height: 100%;
        }
      }
    }
  }
  .noticesCards {
    font-size: 5rem;
    // width: 60vw;
    // max-width: 60vw;
    // height: 20vw;
    // max-height: 22.5vw;

    border: none !important;
    border-radius: 0;
    background: transparent !important;

    padding: 0.5em !important;
    margin: 0 2.5vw;

    overflow: hidden;
    &.firstGuideCards {
      margin-left: 0;
    }
    // &.lastGuideCards {
    //   margin-right: 0;
    // }

    flex-shrink: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    .noticesBackgroundPhoto {
      position: absolute;
      height: 100%;
      width: 50%;
      object-fit: cover;

      left: 0;

      &.noticesBackgroundGif {
        width: 50%;
        object-fit: cover;
        @media (max-width: 1600px) {
          width: 100%;
        }
      }

      // @media (max-width: 1600px) {
      //   left: -5vw;
      // }
      @media (max-width: 1600px) {
        width: 100%;
        object-fit: cover;
        opacity: 0.7;
      }
    }

    .noticesDivCardsContent {
      z-index: 1;
      width: 60%;
      padding: 1.5vw 0;
      .card-header {
        background-color: transparent;
        border: none;
      }
      .CardsTitle {
        font-family: 'Whyte Inktrap';
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 30px;
        @media (max-width: 991px) {
          font-size: 5vw;
          line-height: 5.5vw;

          padding-left: 0;
        }
      }
      .cardsFont {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 25px;
        line-height: 30px;
        & > div {
          margin-top: 2vw;
        }

        @media (max-width: 991px) {
          font-size: 4vw;
          line-height: 4.5vw;
        }
      }

      .guideCardBody {
        // font-family: 'Roboto';
        // font-style: normal;
        // font-weight: normal;
        // font-size: 40px;
        // line-height: 45px;
        flex: inherit;
        @media (max-width: 991px) {
          padding: 0 0 0 0;

          img {
            margin-top: 5vw;
            width: 20vw;
          }
        }
      }
      @media (max-width: 1600px) {
        width: 100%;
      }
    }
    @media (max-width: 991px) {
      padding: 2vw !important;
    }
  }
}
