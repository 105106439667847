@font-face {
  font-family: 'Cosi Times';

  src: url('./COSI\ TIMES\ ROMAN.eot'); /* IE9*/
  src: url('./COSI\ TIMES\ ROMAN.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./COSI\ TIMES\ ROMAN.woff2') format('woff2'),
    /* chrome、firefox */ url('./COSI\ TIMES\ ROMAN.woff') format('woff'),
    /* chrome、firefox */ url('./COSI\ TIMES\ ROMAN.TTF') format('truetype'),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url('./COSI\ TIMES\ ROMAN.otf') format('opentype');

  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cosi Times';

  src: url('./COSI\ TIMES\ LIGHT\ CONDENSED.eot'); /* IE9*/
  src: url('./COSI\ TIMES\ LIGHT\ CONDENSED.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./COSI\ TIMES\ LIGHT\ CONDENSED.woff2') format('woff2'),
    /* chrome、firefox */ url('./COSI\ TIMES\ LIGHT\ CONDENSED.woff')
      format('woff'),
    /* chrome、firefox */ url('./COSI\ TIMES\ LIGHT\ CONDENSED.TTF')
      format('truetype'),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url('./COSI\ TIMES\ LIGHT\ CONDENSED.otf') format('opentype');

  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
