.circle {
    pointer-events: none;
      position: fixed;
      background-color: #fff;
      width: 5px;
      height: 5px;
    left:-2.5px;
    top:-2.5px;
      border-radius: 100%;
      z-index: 1;
      // user-select: none;
      // pointer-events: none;
      z-index: 10000;
      transform: scale(1);
      // &.active {
      //     opacity: 1;
      //     transform: scale(0);
      // }
  
      mix-blend-mode: difference;
  }
  
  .ball {
    width: 50px;
    height: 50px;
    position: fixed;
    top: -25px;
    left: -25px;
    border: 1px solid #fff;
    border-radius: 50%;
    z-index: 999999999999;
    pointer-events: none;
    user-select: none;
  
    mix-blend-mode: difference;
  
  }

  .fslightbox-open {
    .ball {
      z-index: 1;
    }
    .circle {
      z-index: 1;
    }
  }