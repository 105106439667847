.reservationPage {
  .rowSection {
    width: 100%;
    .guideSectionCol {
      &:nth-child(2) {
        padding-bottom: 5vw;

        @media (max-width: 991px) {
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      &.guideSectionColTitle {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        h4 {
          margin-top: 15px;
        }
      }

      .sliderGuide {
        // overflow: hidden;
        @media (max-width: 991px) {
          max-width: 80%;
        }

        .slick-list {
          // overflow: visible;
          padding: 5em 0;
          @media (max-width: 991px) {
            padding: 3em 0;
          }
        }
        .slick-track {
          align-items: center;
          display: flex;
          justify-content: center;
        }

        .slick-dots {
          * {
            cursor: none;
          }
          // bottom: 0;
          li button:before,
          li.slick-active button:before {
            color: theme-color('textColor') !important;
          }
        }
      }

      .sliderGuide .item {
        position: relative;
        //  padding: 150px 80px;
        flex: 0 0 0;
        height: 100%;
        display: flex;
        align-items: center;
        line-height: 1.7;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        border-right: 1px solid rgba(0, 0, 0, 0.06);
        background-color: transparent;
        padding: 0 1em;
        @media (max-width: 991px) {
          padding: 0 3vw;
        }

        .guideCards {
          font-size: 5rem;
          width: 100%;
          // max-width: 500px;
          height: 100%;
          // max-height: 600px;

          padding: 3vw;

          // margin: 0 2.5vw;
          // &.firstGuideCards {
          //   margin-left: 0;
          // }
          // &.lastGuideCards {
          //   margin-right: 0;
          // }

          flex-shrink: 0;
          display: flex;
          align-items: center;
          justify-content: center;

          .guideCardBody {
            // font-family: 'Roboto';
            // font-style: normal;
            // font-weight: normal;
            // font-size: 40px;
            // line-height: 45px;
            flex: inherit;
          }
          .bonusFont {
            font-family: 'Whyte Inktrap';
            font-style: normal;
            font-weight: bold;
            font-size: 0.8vw;
            line-height: 1vw;
          }
        }
        .guideCards.active {
          color: orange;
        }
        .guideCardNumber {
          width: 5vw;
          max-width: 90px;
          height: 5vw;
          max-height: 90px;
          background-color: theme-color('bgColor');
          border: 1px solid theme-color('secondary');
          border-radius: 50%;

          position: absolute;
          top: calc(-5vw / 2);
          right: 25px;

          display: flex;
          justify-content: center;
          align-items: center;
        }

        @media (max-width: 991px) {
          .guideCards {
            font-size: 5rem;
            max-height: 100%;
            height: auto;
            max-width: 100%;
            width: 100%;

            padding: 3vw;
            margin: 0 !important;
            // &.firstGuideCards {
            //   margin-left: 0;
            // }
            // &.lastGuideCards {
            //   margin-right: 0;
            // }

            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .guideCardNumber {
            width: 10vw;
            height: 10vw;
            top: calc(-10vw / 2);
          }
          .cardWrap:nth-last-child(1) {
            padding-right: 5vw;
          }
        }
      }

      .sliderGuide .item:before {
        position: absolute;
        font-size: 100px;
        opacity: 0.13;
        font-weight: bold;
        z-index: -1;
        -webkit-transform: translate(-30px, -50px);
        transform: translate(-30px, -50px);
        line-height: 1;
      }

      .button-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 991px) {
          .magnetic-wrap {
            margin: 0;
          }
        }
      }

      &.guideSectionColButton {
        // padding-top: 5vw;
        @media (max-width: 991px) {
          padding-top: 0;
        }
      }
    }
  }
}
