.vslPage {
  .RootMoreThan {
    width: 100%;
    padding-top: 7.5vw;
    padding-bottom: 7.5vw;

    justify-content: center;

    .rowSection {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
