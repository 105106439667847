.vslPage {
  .rowSection {
    .moreThanRowSection {
      width: 70%;

      &.moreThanRowSectionTextContent {
        margin: 5em 0 6em 0;
      }
      .moreThanCol {
        display: flex;
        justify-content: center;
        align-items: center;

        &.moreThanCard {
          padding: 4vw;
          @media (max-width: 991px) {
            flex: auto;
            margin-bottom: 5vw;
          }
        }
      }
      .moreThanCardDeckAlone {
        position: relative;
        top: -30px;
        .moreThanCard {
          @media (max-width: 991px) {
            margin-bottom: 0;
          }
        }
      }
      .cardStyle {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 35px;
        margin-right: 35px;
        .card-body {
          text-align: center;
        }

        .moreThanCardStar {
          width: 150px;
          height: 150px;
          top: -75px;
          right: -65px;
          z-index: -1;
        }
      }
      @media (max-width: 991px) {
        & {
          width: 80%;
          .cardStyle {
            // padding: ;
            width: 100%;
            margin-left: 0;
            margin-right: 0;
            z-index: 1;
            .moreThanCardStar {
              width: 15vw;
              height: 15vw;
              top: -7.5vw;
              right: -7.5vw;
              z-index: -1;
            }
          }
          .moreThanCardDeckAlone {
            display: block;
            top: 0;
          }
        }
      }
      @media (max-width: 991px) {
        & {
          width: 100%;
        }
        &.moreThanRowSectionTextContent {
          margin: 8vw 0;
        }
      }
    }
  }
}
