.interviewPage {
  .homePlayContainer {
    // height: calc(100% - 30px) !important;
    top: 0;

    &:hover .homePlaySquare {
      opacity: 1;
      transform: rotate(90deg);

      .homePlayIcon {
        transform: rotate(-90deg);
      }
    }
    transition: opacity 0.3s;
    z-index: 10;
    // cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
    //   pointer-events: none;

    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 991px) {
      // & {
      //   // position: relative;
      // }
    }

    .homePlaySquare,
    .homePlayIcon {
      transition: transform 0.3s;
    }
    .homePlaySquare {
      pointer-events: all;

      // border: 1px solid theme-color('secondary');
      background-color: theme-color('primary');
      width: 120px;
      height: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: rotate(45deg);

      .homePlayIcon {
        transform: rotate(-45deg);
        path,
        rect {
          fill: theme-color('bgColor');
          mix-blend-mode: color;
        }
      }
      @media (max-width: 1600px) {
        & {
          width: 8vw;
          height: 8vw;
          max-width: 120px;
          max-height: 120px;

          svg {
            width: 2vw;
          }
        }
      }
      @media (max-width: 991px) {
        & {
          width: 15vw;
          height: 15vw;
          max-width: 120px;
          max-height: 120px;

          svg {
            width: 4vw;
          }
        }
      }
    }

    &.playingVideo {
      opacity: 0 !important;
      // &:hover {
      //   opacity: 0.5;
      // }
    }

    &.videoPlayed {
      @media (min-width: 992px) {
        // &:hover {
        //   opacity: 0.3 !important;
        // }
      }
    }
  }
}
