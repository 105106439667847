.vslPage {
  .RootPayment {
    width: 100%;
    padding-top: 1vw;
    padding-top: 5vw;
    padding-bottom: 7.5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    .rowSection {
      max-width: 90%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
