.vslPage {
  .rowSection {
    .infoColTitleArtist {
      margin-bottom: 5vw;
    }
    .infoColCtaArtist {
      margin-top: 5vw;
      .infoDivCtaArtist {
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          font-size: 35px;
          line-height: 40px;
          font-weight: normal;
          margin-bottom: 5vw;

          @media (max-width: 991px) {
            font-size: 4.5vw;
            line-height: 5vw;
          }
        }
      }
      .buttonStyle {
        position: relative;
        background: theme-color('bgColor');
      }
      .cardStarDivWrap {
        position: absolute;
        bottom: 100%;
        right: 100%;
        z-index: -1;
        .cardStar {
          position: relative;
          margin-bottom: -50%;
          margin-left: 50%;
        }
      }

      .borderDiv {
        width: 50vw;
        height: 11vw;
        position: absolute;
        border: 1px solid theme-color('secondary');
        border-radius: 20px;

        background: theme-color('bgColor');

        @media (max-width: 991px) {
          width: 80vw;
          height: 25vw;
        }
      }

      .infoColCtaArtistSliderWrap {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .slick-slide {
        display: flex;
        justify-content: center;
        padding: 0.5vw 0;

        .infoSliderItem {
          width: fit-content !important;
          opacity: 0.1;

          text-align: center;

          h3 {
            font-family: 'Cosi Times';
            font-style: normal;
            font-weight: normal;
            font-size: 4vw;
            line-height: 4vw;

            margin-bottom: 0;

            @media (max-width: 991px) {
              font-size: 8.5vw;
              line-height: 8.5vw;
            }
          }
        }
        &.slick-center {
          .infoSliderItem {
            opacity: 1 !important;
          }
        }
        @media (max-width: 991px) {
          padding: 2vw 0;
        }
      }
      @media (max-width: 991px) {
        margin-bottom: 5vw;
      }
    }
    .infoCol {
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .infoListGroup {
        max-width: 90%;
        width: 100%;
        .infoListItem {
          background: transparent;
          display: flex;
          border-bottom: 1px solid theme-color('secondary');
          position: relative;

          padding: 5em 4em 2em 0em;
          &:nth-child(1) {
            padding-top: 0;
          }
          &:hover {
            h1 {
              -webkit-text-stroke: 1px theme-color('textColor');
              color: transparent;
              &::before {
                -webkit-text-stroke: 0;
              }
            }
          }
          .infoDivContent {
            padding-right: 250px;
            @media (max-width: 1300px) {
              padding-right: 20vw;
            }
            @media (max-width: 991px) {
              padding-right: 0;
            }
            h1 {
              transition: 0.3s;
              display: flex;
              align-items: center;
              margin-bottom: 20px;
              &::before {
                font-size: 20vw;
                content: '\2022';
                color: theme-color('primary');
                font-weight: bold;
                display: inline-block;
                // width: 1em;
                // margin-left: -1em;
              }
            }
            p {
              font-size: 35px;
              line-height: 40px;
              margin-left: 4.5vw;

              max-width: 70%;

              @media (max-width: 1600px) {
                font-size: 2vw;
                line-height: 2.5vw;
                margin-left: 4.5vw;

                max-width: 100%;
              }
              @media (max-width: 991px) {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 4vw;
                line-height: 4.5vw;
                // margin-left: 4.5vw;
              }
            }
          }
          .infoImg {
            position: absolute;
            width: 300px;
            // height: 400px;
            object-fit: cover;
            transform: translateY(-50%);
            top: 50%;
            right: 0;
            z-index: 1;
            opacity: 0;
            visibility: hidden;
            pointer-events: none;

            @media (max-width: 1300px) {
              width: 20vw;
              // position: relative !important;
              // top: 0;
              // left: 0;
              // transform: none;
            }
            @media (max-width: 991px) {
              width: 20vw;
              position: relative !important;
              top: 0;
              left: 0;
              transform: none;
              margin-left: 2vw;
            }
          }
          @media (max-width: 991px) {
            padding: 5em 0 2em 0em;
          }
        }
        @media (max-width: 991px) {
          max-width: 100%;
        }
      }
    }
  }

  // .venues {
  //     background-color: #faf5ef;

  //     &-heading {
  //       grid-column: 2/9;
  //     }

  //     &-txt {
  //       grid-column: 9/12;
  //       align-self: end;
  //     }

  //     &-items {
  //       position: relative;
  //       grid-column: 2/12;
  //     }

  //     &-item {
  //       width: 100%;
  //       display: flex;
  //       justify-content: space-between;
  //       align-items: flex-end;
  //       border-bottom: 1px solid #1f1f1f;
  //       // cursor: pointer;
  //       padding-bottom: 4rem;
  //       padding-top: 4rem;
  //     }

  //     & h6 {
  //       letter-spacing: 2px;
  //       pointer-events: none;
  //     }

  //     & h2 {
  //       pointer-events: none;
  //     }

  //     &-img {
  //       position: fixed;
  //       top: 0;
  //       left: 0;
  //       width: 70%;
  //       max-width: 400px;
  //       // height: 70%;
  //       background-size: cover;
  //       // background-image: url("/img/venue-1.jpg");
  //     }

  //     &-img-wrap {
  //       width: 30vw;
  //       position: absolute;
  //       top: 0;
  //       left: 0;
  //       overflow: hidden;
  //       pointer-events: none;
  //       visibility: hidden;
  //       padding-bottom: 25vw;
  //     }
  //   }

  //   .imageHovered {
  //       z-index: 11;
  //   }

  //   html, body { height: auto; }
  //   .panel {
  //     height: 100vh;
  //   }

  //   .block {
  //     height: 100vh;
  //   }
}
