// LOCAL
// Cosi Times
@import '../../fonts/cosi-times/style.scss';

// Whyte Ink
@import '../../fonts/whyteInk/style.scss';

// ROBOTO
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;900&display=swap');

body {
  color: theme-color('secondary');
}
.vslPage {
  a:link,
  a:visited {
    color: theme-color('primary') !important;
  }
  h1 {
    font-family: 'Cosi Times';
    font-style: normal;
    font-weight: 400;
    font-size: 4vw;
    line-height: 4vw;

    text-transform: uppercase;
  }
  h2 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 2.2vw;
    line-height: 2.4vw;
  }

  h3 {
    font-family: 'Whyte Inktrap';
    font-style: normal;
    font-weight: bold;
    font-size: 1vw;
    line-height: 1.2vw;

    letter-spacing: 0.085em;
    text-transform: uppercase;
  }

  h4 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 1.5vw;
    line-height: 1.7vw;

    letter-spacing: 0.085em;
    text-align: center;
  }
  h5 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 1.3vw;
    line-height: 1.5vw;
  }

  p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 25px;
    line-height: 30px;
    @media (max-width: 1600px) {
      font-size: 1.5vw;
      line-height: 2vw;
    }
  }

  .footerText {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;

    @media (max-width: 991px) and (min-width: 400px) {
      font-size: 10px;
      line-height: 15px;
    }
    @media (max-width: 399px) {
      font-size: 3vw;
      line-height: 3.5vw;
    }
  }

  li {
    &.liSubtitle {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: normal;
      font-size: 25px;
      line-height: 30px;

      @media (max-width: 1600px) {
        font-size: 2vw;
        line-height: 2.5vw;
      }
    }
    &.liForm {
      font-family: 'Whyte Inktrap';
      font-style: italic;
      font-weight: normal;
      font-size: 15px;
      line-height: 20px;

      &::before {
        position: absolute;
        left: -5px;
        margin-right: 0;
      }
    }
  }

  // CARDS
  .cardsFont {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 2vw;
    line-height: 2.3vw;
  }

  .CardsTitle {
    font-family: 'Whyte Inktrap';
    font-style: normal;
    font-weight: 400;
    font-size: 3.5vw;
    line-height: 4vw;
    letter-spacing: 0.085em;
  }

  // PaymentSection
  .beforePrice {
    font-family: 'Cosi Times';
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 45px;
  }
  .afterPrice {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    font-size: 4.8vw;
    line-height: 5.2vw;
  }
  .saveText {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 1.2vw;
    line-height: 1.5vw;
  }

  //  Discount
  .striked-price {
    // color: transparent;
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: theme-color('textColor');

    position: relative;
    margin-bottom: 0 !important;
    margin-top: 0 !important;

    text-align: center;
  }
  .striked-price * {
    // color: transparent;
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: theme-color('textColor');
  }

  .striked-price:before {
    position: absolute;
    align-content: center;
    content: '';
    width: calc(100% + 30px);
    top: 50%;
    left: 50%;
    border-top: 2px solid;
    border-color: inherit;
    // -webkit-transform: rotate(-15deg);
    // -moz-transform: rotate(-15deg);
    // -ms-transform: rotate(-15deg);
    // -o-transform: rotate(-15deg);
    transform: translate(-50%);
    color: theme-color('textColor') !important;
    opacity: 1;
  }

  //   STYLE
  .upperCase {
    text-transform: uppercase;
  }
  .boldText {
    font-weight: 700;
  }

  @media (max-width: 991px) {
    .titleWarpperElement {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    h1 {
      font-size: 8vw;
      line-height: 8vw; // 6.3vw
    }
    h2 {
      font-size: 4.2vw;
      line-height: 4.6vw;
    }

    h3 {
      font-size: 3vw;
      line-height: 3.2vw;
    }
    h4 {
      font-size: 5.5vw;
      line-height: 5.7vw;
    }
    h5 {
      font-size: 4vw;
      line-height: 4.5vw;
    }
    p {
      font-size: 4vw;
      line-height: 4.5vw;
    }
    .cardsFont {
      font-size: 4vw;
      line-height: 4.8vw;
    }
    .CardsTitle {
      font-size: 8vw;
      line-height: 8.5vw;
    }
    li {
      &.liSubtitle {
        font-size: 4vw;
        line-height: 5vw;
      }
      &.liForm {
        font-size: 15px;
        line-height: 20px;

        &::before {
          position: absolute;
          left: -5px;
          margin-right: 0;
        }
      }
    }
    .beforePrice {
      font-size: 10.8vw;
      line-height: 10.2vw;
    }
    .afterPrice {
      font-size: 20vw;
      line-height: 20.5vw;
    }
    .saveText {
      font-size: 3.2vw;
      line-height: 3.5vw;
    }
  }
}
