// // ABSTRACTS
// @import './abstracts/bootstrap';
// @import './abstracts/custom';

// BASE
@import './base/Typo.scss';

// // GENERAL
// @import './general/General.scss';

// PAGES
@import './pages/home';
@import './pages/payment';
@import './pages/info';
@import './pages/situations';
@import './pages/modelDivider';
@import './pages/guide';
@import './pages/moreThan';
@import './pages/advantages';
@import './pages/reservation';

// SECTIONS
@import './pages/components/homeSection';
@import './pages/components/paymentSection';
@import './pages/components/infoSection';
@import './pages/components/situationsSection';
@import './pages/components/modelDividerSection';
@import './pages/components/guideSection';
@import './pages/components/moreThanSection';
@import './pages/components/advantagesSection';
@import './pages/components/reservationSection';

// COMPONENTS
@import './components/form';
@import './components/3dModels';
@import './components/footer';

// // EXTERNALS
// @import './external/fslightbox';

.afterHeroReservation {
  overflow: hidden;
}
