.vslPage {
  .RootModelDivider {
    width: 100%;
    height: 100%;
    // padding: 3em 0;
    padding-top: 7.5vw;
    padding-bottom: 7.5vw;
    // margin-top: 50px;
    // height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .RootModelDivider-inside {
      background-color: #0b0a0c;
    }
    .RootModelDividerWhite-inside {
      background-color: #fcfbfd;
    }
    .RootModelDivider-inside,
    .RootModelDividerWhite-inside {
      width: 100%;
      height: 100%;

      position: relative;

      overflow: hidden;

      height: 100vh;
      width: 100%;
      // transform: scale(0.5);

      display: flex;
      justify-content: center;
      align-items: center;

      transition: 1s;
      transform-origin: center;

      @media (max-width: 991px) {
        & {
          height: auto;
        }
      }
    }
  }
}
