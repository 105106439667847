.reservationPage {
  .rowSection {
    .situationsCol {
      &.situationsColTitle {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 3em;
      }

      .cardStyle {
        background-color: transparent !important;
        position: relative;
        border: none;
        &:nth-last-child(3) {
          margin-top: 0 !important;
        }
        &:nth-last-child(1) {
          margin-bottom: 0 !important;
        }
        &.active {
          .stuationCardStar {
            display: block;
          }
          .cardsFont {
            opacity: 1;
          }
        }
        padding: 4vw !important;
        width: 70%;
        margin: 0 auto;

        @media (max-width: 991px) {
          & {
            padding: 5vw;
            .cardsFont {
              // padding: 0;
            }
          }
        }

        .cardsFont {
          // font-family: 'Roboto';
          // font-style: normal;
          // font-weight: normal;
          // font-size: 40px;
          // line-height: 45px;

          opacity: 0.2;
        }

        @media (max-width: 991px) {
          & {
            width: 100%;
          }
        }
      }
      .animatedScrollCard {
        // width: 100%;
        // height: 100%;
        // border: 1px solid theme-color('secondary');
        // position: absolute;
        // padding: 5em;

        display: flex;
        align-items: center;
        position: absolute;

        top: 0;
        left: 50%;
        transform: translateX(-50%);
        border: 1px solid theme-color('secondary');
        border-radius: 20px;

        transition: top 0.3s;

        .stuationCardStar {
          // display: none;
          // width: 90px;
          // height: 90px;
          // position: absolute;
          left: -45px;

          @media (max-width: 991px) {
            left: -4vw;
          }
        }
      }
    }
  }
}
